import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
//import Settings from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { useGoogleAuth } from '../lib/googleAuth';

import {
    select_dark_mode,
    set_dark_mode,
} from '../slices/prefsSlice';

const UserProfile = () => {
    const { googleUser, signOut } = useGoogleAuth();

    const dispatch = useDispatch();
    const dark_mode = useSelector(select_dark_mode);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
  };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const MyAvatar = (props) =>
        <Avatar
          alt={googleUser.profileObj.name}
          src={googleUser.profileObj.imageUrl}
          {...props}
        />;

    const on_dark_mode_change = async (event) => {
        const new_value = event.target.checked
        dispatch(set_dark_mode(new_value));
    }

    const on_fullscreen_change = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }

    const fullscreen_toggle =
          <ToggleButton
            value="check"
            selected={!!document.fullscreenElement}
            onChange={on_fullscreen_change}
            sx={{ mx: 2 }}
          >{document.fullscreenElement ? <FullscreenExitIcon /> : <FullscreenIcon />}</ToggleButton>;

    return (
      <React.Fragment>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center'
        }}>
          <Tooltip
            title={`${googleUser.profileObj.givenName} (${googleUser.profileObj.email})`}
          >
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MyAvatar sx={{ width: 32, height: 32 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem readOnly>
          <MyAvatar /> {googleUser.profileObj.name}
        </MenuItem>
        <Divider />
        {/*
        <MenuItem
          disabled
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Preferences
        </MenuItem>
        */}
        <MenuItem>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={fullscreen_toggle}
              onChange={on_fullscreen_change}
              label="Fullscreen"
              labelPlacement="start"
            />
          </FormGroup>
        </MenuItem>
        <MenuItem>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              checked={dark_mode}
              control={<Switch color='default' />}
              onChange={on_dark_mode_change}
              label="Dark mode"
              labelPlacement="start"
            />
          </FormGroup>
        </MenuItem>
        <MenuItem
          onClick={signOut}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
    );
};

export default UserProfile;

