import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
      FLUSH,
      PAUSE,
      PERSIST,
      persistReducer,
      persistStore,
      PURGE,
      REGISTER,
      REHYDRATE,
} from 'redux-persist';

import config from './slices/configSlice';
import orders from './slices/ordersSlice';
import prefs from './slices/prefsSlice';
import xtp from './slices/xtpSlice';

const prefsConfig = {
    key: 'prefs',
    version: 1,
    storage: storage,
};

const rootReducer = combineReducers({
  config: config,
  orders: orders,
  prefs: persistReducer(prefsConfig, prefs),
  xtp: xtp,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
export default store;
