import * as React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import useIsDesktop from '../../lib/isDesktop';
import { show_2dp, show_rmb } from '../../lib/number';

import {
    select_order_trade_ratio,
    select_total_value_traded,
    select_num_orders,
    select_num_cancels,
    select_num_trades,
    select_broker_fees,
    select_message_fees,
} from '../../slices/ordersSlice';

import {
    select_balance,
} from '../../slices/xtpSlice';

const InfoStats = () => {
    const isDesktop = useIsDesktop();

    const order_trade_ratio = useSelector(select_order_trade_ratio);
    const total_value_traded = useSelector(select_total_value_traded);
    const num_orders = useSelector(select_num_orders);
    const num_cancels = useSelector(select_num_cancels);
    const num_trades = useSelector(select_num_trades);
    const broker_fees = useSelector(select_broker_fees);
    const message_fees = useSelector(select_message_fees);
    const balance = useSelector(select_balance);

    const info_text = (text, color) => {
        return (
          <Typography gutterBottom variant="body1" component="span"
            color={color}
            sx={{
              mx: 0.5
            }}
          >{text}</Typography>
        );
    }

    const label_right = (label, value, color) => {
        return info_text(<>{value}&nbsp;{label}</>, color);
    }

    const label_left = (label, value, color) => {
        return info_text(<>{label}&nbsp;{value}</>, color);
    }

    const otr_color = order_trade_ratio <= 3.0 ? 'gray' :
        order_trade_ratio <= 4.5 ? 'warning.main' : 'error.main';
    const otr_main = <b>{show_2dp(order_trade_ratio)}</b>;
    const otr_detail = <span>({num_orders}/{num_trades}, <span style={{ textDecoration: 'line-through red' }}>{num_cancels}</span>)</span>;

    const msg_fee_color = message_fees <= broker_fees ? 'gray' : 'error.main';

    if (isDesktop) {
        return (
          <React.Fragment>
            { label_right("Balance", <b>{show_rmb(balance)}</b>, 'primary') }
            { label_left("Total Value Traded",  <b>{show_rmb(total_value_traded)}</b>, 'gray') }
            { label_left("Orders/Trade", otr_main, otr_color) }
            { info_text(otr_detail, 'gray') }
            { label_left("Broker fees",  <b>{show_rmb(broker_fees)}</b>, 'gray') }
            { label_left("Message fees",  <b>{show_rmb(message_fees)}</b>, msg_fee_color) }
          </React.Fragment>
        );
    } else {
        return (
          <Box
          sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Box
              sx={{
                flex: 1,
                mx: 1,
                display: "flex",
                flexDirection: "column"
              }}
            >
              { label_right("Balance", <b>{show_rmb(balance)}</b>, 'primary') }
              { label_right("Traded", <b>{show_rmb(total_value_traded)}</b>, 'gray') }
            </Box>
            <Divider
              orientation='vertical'
            />
            <Box
              sx={{
                flex: 1,
                mx: 1,
                display: "flex",
                flexDirection: "column"
              }}
            >
              { label_left("O/T", otr_main, otr_color) }
              { label_left("⮱", otr_detail, 'gray') }
            </Box>
          </Box>
        );
    }
}

export default InfoStats;
