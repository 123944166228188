import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';

import { config } from '../lib/config.js';

const axios = require('axios');

export default function Echo(props) {
    const [request, set_request] = useState("");
    const [response, set_response] = useState("");

    const getResponse = async() => {
        const response = await axios.get(`${config.API_URL}/echo/${request}`);
        console.log(`Got response ${response}`)
        set_response(response.data);
    }

    const on_request_change = (e: any) => {
        //console.log(e.target.value);
        set_request(e.target.value);
    }

    return (
        <Card>
          <CardActions>
            <TextField
              required
              id="tradeslist_request"
              label="Request"
              type="text"
              value={request}
              onChange={on_request_change}
            />
            { response === "" &&
              <Button
                variant="contained"
                color="info"
                onClick={getResponse}
              >Send</Button>
            }
          </CardActions>
          { response !== "" &&
            <CardContent>
              <div>{response}</div>
            </CardContent>
          }
        </Card>
    );
}


