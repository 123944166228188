
export const TopicColumn = {
    field: 'topic',
    headerName: 'Event',
    headerAlign: 'center',
    align: 'left',
    minWidth: 60,
    flex: 3,
    renderCell: (cellValues) => {
        let text=cellValues.value;
        let color='text.primary';
        if (cellValues.value === "event_insert") {
            text = "Insert";
            color = 'text.primary';
        } else if (cellValues.value === "event_trade") {
            text = "TRADE";
            color = 'text.primary';
        } else if (cellValues.value === "event_cancel") {
            text = 'Cancel';
            color = 'red';
        } else if (cellValues.value === "event_all_traded") {
            text = 'All Traded';
            color = 'text.primary';
        } else if (cellValues.value === "event_reject") {
            text = 'REJECT';
            color = 'warning.main';
        }
        return (
            <div
              style={{
                  color: color,
                  fontSize: 16,
                  width: "100%",
                  textAlign: "left"
              }}
            >
              {text}
            </div>
        );
    }
};
