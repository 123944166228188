import * as React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

interface FillProps {
  value: number;
}

const Root = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 26,
  borderRadius: 2,
}));

const Value = styled('div')({
  position: 'absolute',
  lineHeight: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const Bar = styled('div')({
  height: '100%',
  '&.low': {
    backgroundColor: '#f44336',
  },
  '&.medium': {
    backgroundColor: '#efbb5aa3',
  },
  '&.high': {
    backgroundColor: '#088208a3',
  },
});

const FillProgress = (props: FillProps) => {
  const { remaining, total } = props;
  const fillValue = (total - remaining) / total;
  const fillPercent = fillValue * 100;

  return (
    <Root>
      <Value>{`${fillPercent.toLocaleString()} %`}</Value>
      <Bar
        className={clsx({
          low: fillPercent < 30,
          medium: fillPercent >= 30 && fillPercent <= 70,
          high: fillPercent > 70,
        })}
        style={{ maxWidth: `${fillPercent}%` }}
      />
    </Root>
  );
}

export default React.memo(FillProgress);
