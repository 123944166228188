import FillProgress from '../FillProgress.js';

export const FillColumn = {
    field: 'fill',
    headerName: 'Fill',
    headerAlign: 'center',
    align: 'center',
    renderCell: (cellValues) => {
        const v = cellValues.value;
        return (
            <FillProgress
              remaining={v.remaining}
              total={v.total}
            />
        );
    }
};
