import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

const RenderChart = (inst) => {
    const to = `/inst/${inst}`;
    const eastmoney_chart_img = `https://webquotepic.eastmoney.com/GetPic.aspx?imageType=r&nid=0.${inst}`;
    const eastmoney_chart = <img width="289" height="138" src={eastmoney_chart_img}/>;

    return (
        <Link
            to={to}
        >
            <Box sx={{
                display: 'inline-flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                color: 'text.primary',
            }}
        >{eastmoney_chart}</Box>
        </Link>
    );
}

export const ChartColumn = {
    field: 'inst_chart',
    headerName: 'Chart',
    headerAlign: 'center',
    align: 'center',
    minHeight: 140,
    minWidth: 290,
    flex: 2,
    renderCell: (cellValues) => {
        return RenderChart(cellValues.value);
    }
}
