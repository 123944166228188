import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ConfigCard from '../components/ConfigCard';
import GridCard from '../components/GridCard.js';

import {
    algo_api_get,
} from '../lib/actions.js';

import { useGoogleAuth } from '../lib/googleAuth';
import useIsDesktop from '../lib/isDesktop';

import {
    select_volarb,
} from '../slices/ordersSlice';

import {
    volarb_columns,
} from '../components/Columns.js';

import {
    control_template,
    volarb_template,
    /*
    quant_hitlift_template,
    router_template,
    algo_template,
    limit_template,
    limit_bid_template,
    limit_ask_template,
    hitlift_template,
    */
} from '../lib/config_template.js';

const Algo = (props) => {
    const { googleUser } = useGoogleAuth();
    const isDesktop = useIsDesktop();

    const volarb = useSelector(select_volarb);

    const visible_height = 'calc(100vh - 150px)';
    const half_visible_height = 'calc(50vh - 75px)';

    const resetSignals = async() => {
        await algo_api_get(googleUser.tokenId, '/signal_reset');
    }

    return (
      <Box
        sx={{
          width: '100%',
          height: visible_height,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignContent: "stretch"
        }}
        flexWrap="wrap"
        overflow="auto"
        flex={1}
        p={2}
      >
        <Box>
          <ConfigCard
            title="Control"
            template={control_template}
          />
          <Button
            variant="contained"
            color="info"
            onClick={resetSignals}
          >Reset signals</Button>
        </Box>
        <Box>
          <ConfigCard
            title="VolArb"
            template={volarb_template}
          />
        </Box>
        <Box
          flex={0.8}
        >
          <GridCard
            expanded={true}
            height='100%'
            width='100%'
            rows={volarb}
            columns={volarb_columns(isDesktop)}
            title="VolArb DB"
            noRowsText="No instruments"
          />
        </Box>
      </Box>
    );
}

export default Algo;
