import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { show_rmb } from '../../lib/number';

import {
    select_total_weighted_pnl_bps,
    select_total_pnl_rmb,
} from '../../slices/ordersSlice';

const PnLStats = () => {
    const total_pnl_rmb = useSelector(select_total_pnl_rmb);
    const total_pnl_bps = useSelector(select_total_weighted_pnl_bps);

    useEffect(() => {
        const title = `${show_rmb(total_pnl_rmb)} PnL, ${total_pnl_bps.toFixed(1)} bps on China!`;
        document.title = title;
    }, [total_pnl_rmb, total_pnl_bps]);

    return (
            <table>
              <tbody>
                <tr>
                  <td align="right"><b>{show_rmb(total_pnl_rmb)}</b></td>
                  <td align="left">PnL</td>
                </tr>
                <tr>
                  <td align="right"><b>{total_pnl_bps.toFixed(1)}</b></td>
                  <td align="left">bps</td>
                </tr>
              </tbody>
            </table>
  );
}

export default PnLStats;
