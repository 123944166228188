import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { config } from '../lib/config.js';

import { append_update } from '../slices/ordersSlice';

//import { demo_order_updates } from '../demo/demo_order_updates-20220331.js';
import { demo_order_updates } from '../demo/demo_order_updates-20220414.js';
//import { demo_order_updates } from '../demo/demo_order_updates2.js';

const DemoData = (props) => {
    const dispatch = useDispatch();

    const [demoIndex, set_demoIndex] = useState(0);
    const [playing, set_playing] = useState(false);

    const next = useCallback(() => {
        if (!config.demo ||
            demoIndex >= demo_order_updates.length) {
            return;
        }

        dispatch(append_update(demo_order_updates[demoIndex]));
        set_demoIndex(demoIndex+1);
    }, [demoIndex, dispatch]);

    useEffect(() => {
        if (config.demo) {
            const interval = setInterval(() => playing && next(), 30)
            return () => clearInterval(interval)
        }
    }, [next, playing]);

    const play = () => {
        set_playing(true);
    }

    const pause = () => {
        set_playing(false);
    }

    return (
        <Box sx={{
          display: 'inline-flex',
          flexDirection: 'row'
        }}>
          <Button
            variant="contained"
            color="info"
            onClick={next}
          >Next</Button>
          &nbsp;
          <Button
            variant="contained"
            color="info"
            onClick={play}
            startIcon={<PlayArrowIcon/>}
          >Play</Button>
          &nbsp;
          <Button
            variant="contained"
            color="info"
            onClick={pause}
            startIcon={<PauseIcon/>}
          >Pause</Button>
          &nbsp;
          { playing &&
            <div>{"Playing"}</div>
          }
          { !playing &&
            <div>{"Paused"}</div>
          }
        </Box>
    );
}

export default DemoData;
