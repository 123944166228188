import OrderStatus from '../OrderStatus.js'

export const StatusColumn = {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    minWidth: 42,
    flex: 4,
    renderCell: (cellValues) => {
        return (
            <OrderStatus
              status={cellValues.value}
            />
        );
    }
};
