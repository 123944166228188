import Box from '@mui/material/Box';

import { timestamp_to_string } from '../../lib/timestamp.js';

export const TimestampColumn = (field='timestamp', isDesktop=true) => {
    const label = isDesktop ? "Timestamp" : "t";
    return {
        field: field,
        headerName: label,
        headerAlign: 'left',
        align: 'left',
        minWidth: 65,
        flex: 4,
        renderCell: (cellValues) => {
            const v = cellValues.value;

            if (typeof(v) === 'number') {
                return (<div>{timestamp_to_string(v)}</div>);
            } else if (typeof(v) === 'object') {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    {
                        v.map((ts, index) => {
                            return <div key={index}>{timestamp_to_string(ts)}</div>
                        })
                    }
                    </Box>
                );

            }
        },
        sortComparator: (v1, v2) => {
            if (typeof(v1) === 'number') {
                return v1-v2;
            } else if (typeof(v1) === 'object') {
                return v1[v1.length-1] - v2[v2.length-1];
            } else {
                return 0;
            }
        }
    }
};
