import * as React from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InfoIcon from '@mui/icons-material/Info';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit',
  },
  '&.Pending': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`,
  },
  '&.OnMarket': {
    color: theme.palette.info.dark,
    border: `1px solid ${theme.palette.info.main}`,
  },
  '&.Complete': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`,
  },
  '&.Cancelled': {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.main}`,
  },
  '&.Error': {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

interface StatusProps {
  status: string;
}

const OrderStatus = (props: StatusProps) => {
  const { status } = props;

  let label: string = status;
  let icon: any = null;
  if (status === 'Error') {
    icon = <ReportProblemIcon className="icon" />;
  } else if (status === 'OnMarket') {
    label = "On Market";
    icon = <InfoIcon className="icon" />;
  } else if (status === 'Pending') {
    icon = <AutorenewIcon className="icon" />;
  } else if (status === 'Complete') {
    icon = <DoneIcon className="icon" />;
  } else if (status === 'Cancelled') {
    icon = <HighlightOffIcon className="icon" />;
  }

  return (
    <StyledChip
      className={status}
      icon={icon}
      size="small"
      label={label}
      variant="outlined"
      />
  );
}

export default React.memo(OrderStatus);
