import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Assets from './views/Assets.js';
import Algo from './views/Algo.js';
import Stats from './views/Stats.js';
import Market from './views/Market.js';
import OrderUpdates from './views/OrderUpdates.js';
import Inst from './views/Inst.js';
import Trades from './views/Trades.js';
import Echo from './components/Echo.js';

const transform="grow-9 up-12";
const robot_transform="grow-8 up-12";
const suit_transform="grow-11 up-12";

export const routes = [
    {
        label: "Trading",
        icon: <FontAwesomeIcon icon={solid('arrow-trend-up')} transform={transform} />,
        path: "/",
        element: <Trades className="p-3 m-3" />,
    },
    {
        label: "Algo",
        icon: <FontAwesomeIcon icon={solid('robot')} transform={robot_transform} />,
        path: "/algo",
        element: <Algo className="p-3 m-3" />,
    },
    {
        label: "Market",
        icon: <FontAwesomeIcon icon={solid('hand-holding-medical')} transform={transform} />,
        path: "/market",
        element: <Market className="p-3 m-3" />,
    },
    {
        label: "Broker",
        icon: <FontAwesomeIcon icon={solid('user-tie')} transform={suit_transform} />,
        path: "/broker",
        element: <Assets className="p-3 m-3" />,
    },
    {
        label: "Stats",
        icon: <FontAwesomeIcon icon={solid('user-tie')} transform={suit_transform} />,
        path: "/stats",
        element: <Stats className="p-3 m-3" />,
    },
    {
        label: "Stream",
        icon: <FontAwesomeIcon icon={solid('bars-staggered')} transform={transform} />,
        path: "/stream",
        element: <OrderUpdates className="p-3 m-3" />,
    },
];

export const hidden_routes = [
    {
        label: "Inst",
        path: "/inst/:inst",
        element: <Inst className="p-3 m-3"/>,
    },
    {
        label: "Echo",
        path: "/echo",
        element: <Echo className="p-3 m-3"/>,
    },
];
