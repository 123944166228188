
export const SideColumn = {
    field: 'side',
    headerName: 'Side',
    headerAlign: 'center',
    align: 'center',
    width: 30,
    flex: 2,
    renderCell: (cellValues) => {
        let text=cellValues.value;
        let color='text.primary';
        if (cellValues.value === "ask") {
            text = "ASK";
            color = 'red';
        } else if (cellValues.value === "bid") {
            text = "BID";
            color = 'blue';
        }
        return (
            <div
              style={{
                  color: color,
                  fontSize: 12,
                  textAlign: "center"
              }}
            >
              {text}
            </div>
        );
    }
};
