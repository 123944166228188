import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orig_balance: 0,
    balance: 0
};

export const xtpSlice = createSlice({
    name: 'xtp',
    initialState,
    reducers: {
        set_orig_balance: (state, action) => {
            state.orig_balance = action.payload;
        },
        set_balance: (state, action) => {
            state.balance = action.payload;
        },
    }
});

export const {
    set_orig_balance,
    set_balance,
} = xtpSlice.actions;

export const select_orig_balance = state =>
    state.xtp.orig_balance;

export const select_balance = state =>
    state.xtp.balance;

export default xtpSlice.reducer;
