import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import { pad6, eastmoney_chart_img } from '../lib/eastmoney';
import { get_stock, get_industry_icon } from '../lib/stocks';

export const RenderInst = (inst) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const to = `/inst/${inst}`;
    const industry_icon = get_industry_icon(inst);
    const stock = get_stock(inst);
    const company_name_en = stock ? stock["English name"] : "";
    const eastmoney_chart = <img width="289" height="138" src={eastmoney_chart_img(inst)}/>;

    return (
        <Link
            to={to}
        >
            <Box sx={{
                fontFamily: 'Monospace',
                fontSize: 12,
                display: 'inline-flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                color: 'text.primary',
                px: 0.2,
                py: 0.3,
                border: '1px solid gray'
            }}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >{industry_icon} {pad6(inst)}</Box>
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
        {company_name_en}<br/>
        {eastmoney_chart}
      </Popover>
        </Link>
    );
}

const InstLabel = (props) => RenderInst(props.inst);

export default React.memo(InstLabel);
