import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { show_2dp } from '../lib/number';

import {
    send_fill_statistics_query,
    send_latency_statistics_query,
} from '../lib/actions.js';

import { useGoogleAuth } from '../lib/googleAuth';

import {
    select_fill_statistics,
    select_latency_statistics,
} from '../slices/ordersSlice';


const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'scroll',
    height: '600px',
  },
}));

/*
 * {"data":{"asks":{"cancel_miss_count":0,"cancelled_count":0,"fill_count":0,"miss_count":0},"bids":{"cancel_miss_count":0,"cancelled_count":84,"fill_count":20,"miss_count":0},"hits":{"cancel_miss_count":0,"cancelled_count":0,"fill_count":43,"miss_count":29},"lifts":{"cancel_miss_count":0,"cancelled_count":0,"fill_count":0,"miss_count":4}},"type":"fill_statistics"}
 */

const Stats = (props) => {
    const { googleUser } = useGoogleAuth();
    const classes = useStyles();
    //const theme = useTheme();

   //const fill_stats = JSON.parse('{"asks":{"cancel_miss_count":0,"cancelled_count":0,"fill_count":0,"miss_count":0},"bids":{"cancel_miss_count":0,"cancelled_count":84,"fill_count":20,"miss_count":0},"hits":{"cancel_miss_count":0,"cancelled_count":0,"fill_count":43,"miss_count":29},"lifts":{"cancel_miss_count":0,"cancelled_count":0,"fill_count":0,"miss_count":4}}');
    const fill_stats = useSelector(select_fill_statistics);
    const latency_stats = useSelector(select_latency_statistics);

    const getResponse = async() => {
        await send_fill_statistics_query(googleUser.tokenId);
        await send_latency_statistics_query(googleUser.tokenId);
    }

    const display_fill_stats = (title, stats) => {
        let s = typeof(stats);
        console.log(`stats: ${typeof(stats)}`);
        if (typeof(stats) !== 'undefined') {
            let total = stats.fill_count + stats.miss_count + stats.cancelled_count + stats.cancel_miss_count;
            let total_cancels = stats.cancelled_count + stats.cancel_miss_count;
            let fill_rate = total > 0 ? 100.0 * stats.fill_count / total : 0.0
            let cancel_rate = total_cancels > 0 ? 100.0 * stats.cancelled_count / total_cancels : 0.0
            return (
                <tr>
                    <th>{title}</th>
                    <td align="center">{stats.fill_count} / {total}</td>
                    <td>{show_2dp(fill_rate)}%</td>
                    <td align="center">{stats.cancelled_count} / {total_cancels}</td>
                    <td>{show_2dp(cancel_rate)}%</td>
                </tr>
            );
        } else {
            return (
                <tr>
                    <th>{title}</th>
                    <td align="center">0 / 0</td>
                    <td>-.-- %</td>
                    <td align="center">0 / 0</td>
                    <td>-.-- %</td>
                </tr>
            );
        }
    }

    const display_latency_stats = (stats) => {
        let numeric_keys = Object.keys(stats).map(parseFloat).sort();
        return (
            <table>
            { numeric_keys.map(key =>
                <tr><th align="right">{show_2dp(parseFloat(key))}</th><td align="right">{show_2dp(stats[key])}</td></tr>
              )
            }
            </table>
        );
    }

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
        }}>

        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="span">Fill Statistics</Typography>
          </CardContent>
          <CardActions>
              <Button
                variant="contained"
                color="info"
                onClick={getResponse}
              >Update stats</Button>
          </CardActions>
          <CardContent>
            { typeof(fill_stats) !== "undefined" &&
                <div>
                <table cellpadding="10" border="1">
                    <tr><th></th><th colspan="2">Inserts</th><th colspan="2">Cancels</th></tr>
                    { display_fill_stats("Bids", fill_stats.bids) }
                    { display_fill_stats("Asks", fill_stats.asks) }
                    { display_fill_stats("Hits", fill_stats.hits) }
                    { display_fill_stats("Lifts", fill_stats.lifts) }
                </table>
                </div>
            }
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="span">Latency Statistics</Typography>
          </CardContent>
          <CardContent className={classes.root}>
            { typeof(latency_stats) !== "undefined" &&
                <div>
                <table cellpadding="10" border="1">
                    <tr>
                      <th></th>
                      <th colspan="1">Internal</th>
                      <th colspan="1">Broker</th>
                    </tr>
                { Object.keys(latency_stats).map(key => 
                    <tr>
                      <th>{key}</th>
                      <td>{display_latency_stats(latency_stats[key]["internal"])}</td>
                      <td>{display_latency_stats(latency_stats[key]["broker"])}</td>
                    </tr>
                  )
                }
                </table>
                </div>
            }
          </CardContent>
        </Card>

      </Box>
    );
}

export default Stats;
