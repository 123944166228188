import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        config_set: (state, action) => {
            const [ field, value ] = action.payload;
            console.log(`config_set: set ${field} to ${value}`);
            state[field] = value;
        },
        config_set_all: (state, action) => {
            const config = action.payload;
            console.log(`config_set_all: ${JSON.stringify(config)}`);
            for (const field in config) {
                state[field] = config[field];
            }
        }
    }
});

export const {
    config_set,
    config_set_all
} = configSlice.actions;

export const config_get = (field) => state =>
    state.config[field];

export default configSlice.reducer;
