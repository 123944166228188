import React, {
    createRef,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import {
    select_dark_mode,
} from '../../slices/prefsSlice';

import {
    ConfigFromTemplate,
} from '../../components/Config';

import {
    control_template
} from '../../lib/config_template';

const MasterControl = () => {

    const dark_mode = useSelector(select_dark_mode);
    const paper_bg = dark_mode ? 'black' : 'khaki';

    const fields = [
        "config.control.on_off",
        "config.control.sell_only",
        "config.control.trade_out",
        "config.control.action_budget"
    ];

    let template = {};

    fields.forEach(field => {
        template[field] = control_template[field];
    });

    const refs = useRef({});

    refs.current = Object.fromEntries(Object.entries(template).map(([field, x]) => [field, createRef()]));

    return (
      <Paper
          elevation={3}
          sx={{
              m: 1,
              p: 0.5,
              backgroundColor: paper_bg,
          }}
      >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >

      { Object.entries(template).map(([field, x]) => {
          return (
            <Box
              key={field}
            >
            <ConfigFromTemplate
              ref={refs.current[field]}
              field={field}
              values={x}
            />
            </Box>
          );
        })
      }
      </Box>
      </Paper>
    );
}

export default MasterControl;
