import React, {
    forwardRef,
    useImperativeHandle,
    useCallback,
    useEffect,
    useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import SendIcon from '@mui/icons-material/Send';

import {
    send_config_get,
    send_config_set,
} from '../lib/actions.js';

import { useGoogleAuth } from '../lib/googleAuth';

import {
    config_set,
    config_get,
} from '../slices/configSlice';

export const ConfigBoolCommand = forwardRef((props, ref) => {
    const value = useSelector(config_get(props.field));

    const { googleUser } = useGoogleAuth();

    const send_set_value = async (new_value: boolean) => {
        if (googleUser) {
            send_config_set(googleUser.tokenId, props.field, new_value);
        } else {
            console.log(`ConfigBoolCommand: googleUser: ${JSON.stringify(googleUser)}`);
        }
    }

    const refresh = useCallback(async () => {
        if (googleUser) {
            send_config_get(googleUser.tokenId, props.field);
        } else {
            console.log(`ConfigBoolCommand: googleUser: ${JSON.stringify(googleUser)}`);
        }
    }, [googleUser, props]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useImperativeHandle(ref, () => ({
        doRefresh() {
            refresh();
        }
    }));

    return (
      <Box
        sx={{
          mx: 1,
          height: "100%",
          verticalAlign: "middle"
        }}
      >
      { value !== true &&
        <Button
          variant="contained"
          color={props.color_set_true}
          sx={{
            height: "100%",
            verticalAlign: "middle"
          }}
          onClick={() => send_set_value(true)}
        >{props.label_set_true}</Button>
      }
      { value === true &&
        <Button
          variant="contained"
          color={props.color_set_false}
          sx={{
            height: "100%",
            verticalAlign: "middle"
          }}
          onClick={() => send_set_value(false)}
        >{props.label_set_false}</Button>
      }
      </Box>
    );
});

export const ConfigBoolSwitch = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const value = useSelector(config_get(props.field));

    const { googleUser } = useGoogleAuth();

    const send_set_value = async (new_value: boolean) => {
        if (googleUser) {
            send_config_set(googleUser.tokenId, props.field, new_value);
            dispatch(config_set([props.field, new_value]));
        }
    }

    const on_value_change = async (event) => {
        const new_value = event.target.checked
        console.log(`Toggle ${props.field} to ${new_value}`);
        send_set_value(new_value)
    }

    const refresh = useCallback(async () => {
        if (googleUser) {
            send_config_get(googleUser.tokenId, props.field);
        }
    }, [googleUser, props]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useImperativeHandle(ref, () => ({
        doRefresh() {
            refresh();
        }
    }));

    return (
      <Box
        sx={{
          mx: 1,
          height: "100%",
          verticalAlign: "middle",
        }}
      >
      <FormGroup
        aria-label="position"
        row
        sx={{
          height: "100%",
          verticalAlign: "middle"
        }}
      >
        <FormControlLabel
          checked={value}
          control={<Switch color={props.color} />}
          onChange={on_value_change}
          label={props.label}
          labelPlacement="start"
        />
      </FormGroup>
      </Box>
    );
});

export const ConfigDouble = forwardRef((props, ref) => {
    const { googleUser } = useGoogleAuth();
    const value = useSelector(config_get(props.field));

    const [draft_value, set_draft_value] = useState(0);

    const send_set_value = async () => {
        if (googleUser) {
            send_config_set(googleUser.tokenId, props.field, draft_value);
        }
    }

    const on_value_change = async (e: any) => {
        set_draft_value(parseFloat(e.target.value));
    }

    useEffect(() => {
        set_draft_value(value);
    }, [value]);

    const refresh = useCallback(async () => {
        if (googleUser) {
            const response = send_config_get(googleUser.tokenId, props.field);
            if (typeof(response['data']) !== 'undefined') {
                const value = response['data']['value'];
                if (typeof(value) !== 'undefined') {
                    set_draft_value(value);
                }
            }
        }
    }, [googleUser, props]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useImperativeHandle(ref, () => ({
        doRefresh() {
            refresh();
        }
    }));

    return (
      <Box
        sx={{
          mx: 1,
          width: "100%",
          height: "100%",
          verticalAlign: "middle",
        }}
        display="flex"
        flexDirection="row"
      >
        <TextField
          required
          flex={8}
          fullWidth
          id={props.field}
          label={props.label}
          InputProps={{
            type: 'number',
          }}
          inputProps={{
            step: props.step,
            min: props.min,
            max: props.max,
          }}
          type="number"
          value={draft_value}
          onChange={on_value_change}
        /><IconButton
          flex={1}
          variant="contained"
          color={props.color}
          onClick={send_set_value}
        ><SendIcon/></IconButton>
      </Box>
    );
});

export const ConfigInt = forwardRef((props, ref) => {
    const { googleUser } = useGoogleAuth();
    const value = useSelector(config_get(props.field));

    const [draft_value, set_draft_value] = useState(0);

    const send_set_value = async () => {
        if (googleUser) {
            send_config_set(googleUser.tokenId, props.field, draft_value);
        }
    }

    const on_value_change = async (e: any) => {
        set_draft_value(parseInt(e.target.value));
    }

    useEffect(() => {
        set_draft_value(value);
    }, [value]);

    const refresh = useCallback(async () => {
        if (googleUser) {
            const response = send_config_get(googleUser.tokenId, props.field);
            if (typeof(response['data']) !== 'undefined') {
                const value = response['data']['value'];
                if (typeof(value) !== 'undefined') {
                    set_draft_value(value);
                }
            }
        }
    }, [googleUser, props]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useImperativeHandle(ref, () => ({
        doRefresh() {
            refresh();
        }
    }));

    return (
      <Box
        sx={{
          mx: 1,
          width: "100%",
          height: "100%",
          verticalAlign: "middle",
        }}
        display="flex"
        flexDirection="row"
      >
        <TextField
          required
          flex={8}
          fullWidth
          id={props.field}
          label={props.label}
          InputProps={{
            type: 'number',
          }}
          inputProps={{
            step: props.step,
            min: props.min,
            max: props.max,
            type: 'number',
          }}
          type="number"
          value={draft_value}
          onChange={on_value_change}
        /><IconButton
          flex={1}
          variant="contained"
          color={props.color}
          onClick={send_set_value}
        ><SendIcon/></IconButton>
      </Box>
    );
});

export const ConfigFromTemplate = forwardRef((props, ref) => {
    const field = props.field;
    const x = props.values;

    if (x.input_type === "binary") {
      return (
        <ConfigBoolCommand
          ref={ref}
          field={field}
          label_set_true={x.label_set_true}
          color_set_true={x.color_set_true}
          label_set_false={x.label_set_false}
          color_set_false={x.color_set_false}
        />
      );
    } else if (x.input_type === "switch") {
      return (
        <ConfigBoolSwitch
          ref={ref}
          field={field}
          label={x.label}
          color={x.color}
        />
      );
    } else if (x.input_type === "double") {
      return (
        <ConfigDouble
          ref={ref}
          field={field}
          label={x.label}
          step={x.step}
          min={x.min}
          max={x.max}
          color="info"
        />
      );
    } else if (x.input_type === "int") {
      return (
        <ConfigInt
          ref={ref}
          field={field}
          label={x.label}
          step={x.step}
          min={x.min}
          max={x.max}
          color={x.color}
        />
      );
    }

    return {};
});

