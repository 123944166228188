import { createSlice } from '@reduxjs/toolkit';

import { object_values } from '../lib/object_utils.js';

const initialState = {
    last_msg_timestamp: 0,
    total_pnl_rmb: 0,
    total_value_traded: 0,
    total_weighted_pnl_bps: 0,
    num_orders: 0,
    num_cancels: 0,
    num_trades: 0,
    order_trade_ratio: 0,
    message_fees: 0,
    broker_fees: 0,
    exchange_fees: 0,
    positions: {},
    positions_rows: [],
    all_updates: {},
    all_updates_rows: [],
    active_orders: {},
    active_orders_rows: [],
    trades: {},
    trades_rows: [],
    volatility: {},
    market: {},
    market_rows: [],
    denylist: {},
    fill_statistics: {},
    latency_statistics: {},
    volarb: {},
    volarb_rows: [],
}

const tiny_update_to_row = (update) => {
    const price = update.p / 1000;
    let topic = "";
    if (update.ty == "i") {
        topic = "event_insert";
    } else if (update.ty == "c") {
        topic = "event_cancel";
    } else if (update.ty == "t") {
        topic = "event_trade";
    } else if (update.ty == "r") {
        topic = "event_reject";
    } else if (update.ty == "p") {
        topic = "event_position";
    }
    return {
        id: update.ix,
        timestamp: update.ts,
        topic: topic,
        oid_cancel: update.oi,
        oid: update.oi,
        xtp_order_id: update.xi,
        inst: update.i,
        side: update.s,
        qty: update.q,
        price: {
            price: price,
            side: update.s,
        },
        status: update.st,
        internal_latency: update.il,
        broker_latency: update.bl,
        fill: {
            remaining: update.rq,
            total: update.q,
        },
        by: {
            insert_user: update.iu,
            cancel_user: update.cu,
        }
    };
}

const update_to_row = (update) => {
    //console.log(`update_to_row: ${JSON.stringify(update)}`);
    const managed_order = update.managed_order;
    const order = managed_order.order;
    const order_event = update.order_event;
    let timestamp = Math.max(managed_order.insert_time, managed_order.cancel_time);
    let status = order.status;
    let qty = order.qty;
    let price = order.price / 1000;
    let internal_latency = 0;
    let broker_latency = 0;
    if (order_event.topic === 'event_insert') {
        internal_latency = managed_order.insert_stats.order_server_sent_to_broker - managed_order.insert_stats.event.timestamp;
        broker_latency = managed_order.insert_stats.order_server_confirm_from_broker - managed_order.insert_stats.order_server_sent_to_broker;
    } else if (order_event.topic === 'event_cancel') {
        internal_latency = managed_order.cancel_stats.order_server_sent_to_broker - managed_order.cancel_stats.event.timestamp;
        broker_latency = managed_order.cancel_stats.order_server_confirm_from_broker - managed_order.cancel_stats.order_server_sent_to_broker;
        if (managed_order.remaining_qty === order.qty) {
            status = 'Cancelled';
        }
    } else if (typeof(order_event.trade_time) !== 'undefined') {
        timestamp = Math.max(timestamp, order_event.trade_time);
        qty = order_event.trade.qty;
        price = order_event.trade.price / 1000;
    }

    if (order_event.topic === 'event_trade') {
    }

    return {
        id: update.update_index,
        timestamp: timestamp,
        topic: order_event.topic,
        oid_cancel: order.oid,
        oid: order.oid,
        xtp_order_id: managed_order.xtp_order_id,
        inst: order.inst,
        side: order.side,
        qty: qty,
        price: {
            price: price,
            side: order.side
        },
        status: status,
        internal_latency: internal_latency,
        broker_latency: broker_latency,
        fill: {
            remaining: managed_order.remaining_qty,
            total: order.qty
        },
        by: {
            insert_user: managed_order.insert_user,
            cancel_user: managed_order.cancel_user
        }
    };
}

const update_active_orders = (state, f) => {
    let current_active_orders = state.active_orders;
    f(current_active_orders);
    state.active_orders = current_active_orders;
    state.active_orders_rows = object_values(current_active_orders);

    return state;
}

const update_trades = (state, f) => {
    let current_trades = state.trades;
    f(current_trades);
    state.trades = current_trades;
    state.trades_rows = object_values(current_trades);

    return state;
}

const state_append_row = (state, row, register_position) => {
    state.all_updates[row.id] = row;
    state.all_updates_rows = object_values(state.all_updates);

    if (row.topic === 'event_trade') {
        update_trades(state, ts => ts[row.id] = row);
    }

    if (row.status === 'OnMarket') {
        if (register_position) {
            update_active_orders(state, os => os[row.oid] = row);
        }
    } else {
        update_active_orders(state, os => delete os[row.oid]);
    }

    return state;
}

const state_append_update = (state, order_update, register_position) => {
    if (order_update.topic === "order_update") {
        let row = update_to_row(order_update);
        return state_append_row(state, row, register_position);
    } else if (order_update.topic === "u") {
        let row = tiny_update_to_row(order_update);
        return state_append_row(state, row, register_position);
    } else {
        return state;
    }
}

const state_set_active_orders = (state, active_orders) => {
    if (typeof(active_orders) === 'object') {
        return;
    }

    state.active_orders = {};
    state.active_orders_rows = [];

    active_orders.forEach(managed_order => {
        const order = managed_order.order;
        const timestamp = Math.max(managed_order.insert_time, managed_order.cancel_time);
        let status = order.status;

        const row = {
            id: (order.oid * 2),
            timestamp: timestamp,
            topic: 'event_insert',
            oid_cancel: order.oid,
            oid: order.oid,
            xtp_order_id: managed_order.xtp_order_id,
            inst: order.inst,
            side: order.side,
            qty: order.qty,
            price: {
                price: order.price / 1000,
            side: order.side
            },
            status: status,
            fill: {
                remaining: managed_order.remaining_qty,
                total: order.qty
            },
            by: {
                insert_user: managed_order.insert_user,
                cancel_user: managed_order.cancel_user
            }

        };
        state = state_append_row(state, row, true);
    });

    return state;
}

export const state_set_trading_statistics = (state, statistics) => {
    if (typeof(statistics) === 'undefined') {
        return;
    }

    state.last_msg_timestamp = statistics.last_msg_timestamp;
    state.num_orders = statistics.num_orders;
    state.num_cancels = statistics.num_cancels;
    state.num_trades = statistics.num_trades;
    state.order_trade_ratio = statistics.order_trade_ratio;
    state.message_fees = statistics.message_fees;

    return state;
}

export const state_set_fill_statistics = (state, statistics) => {
    if (typeof(statistics) === 'undefined') {
        return;
    }

    state.fill_statistics = statistics;

    return state;
}

export const state_set_latency_statistics = (state, statistics) => {
    if (typeof(statistics) === 'undefined') {
        return;
    }

    state.latency_statistics = statistics;

    return state;
}

const init_volarb = (state, inst) => {
    state.volarb[inst] = {
        "id": inst,
        "timestamp": 0,
        "model_fair": { price: 0.0},
        "model_vola": 0.0,
        "model_step_bps": 0.0,
        "model_update_bps": 0.0,
        "model_exposure": { price: 0.0},
        "model_max_position": 0,
        "model_stop_loss": { price: 0.0}
    };
}

export const state_set_volarb_model = (state, data) => {
    if (typeof(data) === 'undefined') {
        return;
    }

    console.log(`volarb_model: ${JSON.stringify(data)}`);

    state.volarb = {};
    data.forEach(row => {
        const inst = row["inst"];
        if (typeof(state.volarb[inst] === 'undefined')) {
            init_volarb(state, inst);
        }
        state.volarb[inst]["inst"] = inst;
        state.volarb[inst]["timestamp"] = row["timestamp"];
        state.volarb[inst]["model_fair"] = { price: row["fair"] };
        state.volarb[inst]["model_vola"] = row["vola"];
        state.volarb[inst]["model_step_bps"] = row["step_bps"];
        state.volarb[inst]["model_update_bps"] = row["update_bps"];
        state.volarb[inst]["model_exposure"] = { price: row["exposure"] };
        state.volarb[inst]["model_max_position"] = row["max_position"];
        state.volarb[inst]["model_stop_loss"] = { price: row["stop_loss"] };

        state.volatility[inst] = row["vola"];
    });
    state.volarb_rows = object_values(state.volarb);
    console.log(state.volarb_rows);

    return state;
}


export const state_set_opportunity_report = (state, opportunity_report) => {
    if (typeof(opportunity_report) === 'undefined') {
        return;
    }

    opportunity_report.forEach(([inst, report]) => {
        //state.volatility[inst] = report.volatility * 10000;
        //console.log(`OPP inst ${inst} volatility ${report.volatility}`);

        state.market[inst] = {
            id: inst,
            inst: inst,
            inst_chart: inst,
            denied: {
                inst: inst,
                denied: !!state.denylist[inst]
            },
            volatility: state.volatility[inst],
            bid_opp: report.best_bid,
            ask_opp: report.best_ask
        };
    });

    state.market_rows = object_values(state.market);

    return state;
}

export const state_set_position_report = (state, position_report) => {
    if (typeof(position_report) === 'undefined') {
        return;
    }

    state.total_value_traded = position_report.total_position.total_value_traded / 1000.0;
    state.total_pnl_rmb = position_report.total_valuation.pnl / 1000.0;
    state.total_weighted_pnl_bps = position_report.total_valuation.bps;
    state.broker_fees = position_report.total_valuation.fees / 1000.0 / 2.0;
    state.exchange_fees = position_report.total_valuation.fees / 1000.0 / 2.0;

    //console.log(`Got position_report ${JSON.stringify(position_report)}`);

    position_report.total_position.positions.forEach(([inst, position]) => {
        const last_bid_price = {
                price: position.last_bid_price / 1000.0,
                side: 'bid'
              };
        const last_ask_price = {
                price: position.last_ask_price / 1000.0,
                side: 'ask'
              };
        const bought = position.qty > 0 ? last_bid_price.price : 0.0;
        const volatility = inst in state.volatility ? state.volatility[inst] : 0.0;
        state.positions[inst] = {
            id: inst,
            inst: inst,
            denied: {
                inst: inst,
                denied: !!state.denylist[inst]
            },
            volatility: volatility,
            qty: position.qty,
            fair : {
                qty: position.qty,
                bought : bought
            },
            count_trades: position.count_trades,
            volume_traded: position.volume_traded,
            value_traded: position.value_traded / 1000.0,
            last_bid_price: last_bid_price,
            last_ask_price: last_ask_price,
            last_prices: [last_bid_price, last_ask_price],
            last_price: position.last_price / 1000.0,
            cash: position.cash / 1000.0,
        };
    });

    position_report.total_valuation.valuations.forEach(([inst, valuation]) => {
        state.positions[inst].fair.price = valuation.fair / 1000.0;
        state.positions[inst].pnl = valuation.pnl / 1000.0;
        state.positions[inst].bps = valuation.bps;

        //console.log(`Got position for ${inst}: ${JSON.stringify(state.positions[inst])}`);
    });

    state.positions_rows = object_values(state.positions);

    return state;
}

const state_process_batch = (state, batch) => {
    batch.forEach(update => {
        if (update) {
            if (update['type'] === 'trading_statistics') {
                state_set_trading_statistics(state, update.data);
            } else if (update['type'] === 'position_report') {
                state_set_position_report(state, update.data);
            } else if (update['type'] === 'opportunity_report') {
                state_set_opportunity_report(state, update.data);
            } else {
                state_append_update(state, update, true);
            }
        }
    });

    return state;
}

const empty_position = (state, inst) => {
    const volatility = inst in state.volatility ? state.volatility[inst] : 0.0;
    const last_bid_price = {
        price: 0.0,
        side: 'bid'
        };
    const last_ask_price = {
        price: 0.0,
        side: 'ask'
        };

    return {
        id: inst,
        inst: inst,
        denied: {
            inst: inst,
            denied: !!state.denylist[inst]
        },
        volatility: volatility,
        qty: 0,
        count_trades: 0.0,
        volume_traded: 0.0,
        value_traded: 0.0,
        last_bid_price: last_bid_price,
        last_ask_price: last_ask_price,
        last_prices: [last_bid_price, last_ask_price],
        last_price: 0.0,
        cash: 0.0,
        fair: { price: 0.0 },
        pnl: 0.0,
        bps: 0.0
    };
}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        reset_state: (state, action) => {
            state = initialState
        },
        set_opportunity_report: (state, action) => {
            state_set_opportunity_report(state, action.payload);
        },
        set_position_report: (state, action) => {
            state_set_position_report(state, action.payload);
        },
        set_active_orders: (state, action) => {
            state_set_active_orders(state, action.payload);
        },
        set_trading_statistics: (state, action) => {
            state_set_trading_statistics(state, action.payload);
        },
        set_fill_statistics: (state, action) => {
            state_set_fill_statistics(state, action.payload);
        },
        set_latency_statistics: (state, action) => {
            state_set_latency_statistics(state, action.payload);
        },
        set_volarb_model: (state, action) => {
            state_set_volarb_model(state, action.payload);
        },
        append_update: (state, action) => {
            state_append_update(state, action.payload, true);
        },
        append_update_batch: (state, action) => {
            state_process_batch(state, action.payload);
        },
        append_updates: (state, action) => {
            if (Array.isArray(action.payload)) {
                action.payload.forEach(x => {
                    if (x) {
                        state = state_append_update(state, x, false);
                    }
                });
            }
        },
        inst_deny: (state, action) => {
            const inst = action.payload;
            //console.log(`inst_deny: ${inst}`);
            state.denylist[inst] = true;
            state.positions[inst].denied = {
                inst: inst,
                denied: true
            };
            state.positions_rows = object_values(state.positions);
        },
        inst_allow: (state, action) => {
            const inst = action.payload;
            //console.log(`inst_allow: ${inst}`);
            state.denylist[inst] = false;
            state.positions[inst].denied = {
                inst: inst,
                denied: false
            };
            state.positions_rows = object_values(state.positions);
        },
        denylist_set: (state, action) => {
            const denylist = action.payload;
            //console.log(`denylist_set: ${denylist}`);
            state.denylist = {};
            denylist.forEach(x => state.denylist[x] = true);
            for (const inst in state.positions) {
                state.positions[inst].denied = {
                    inst: inst,
                    denied: !!state.denylist[inst]
                };
            }
            state.positions_rows = object_values(state.positions);
        },
    }
});

export const {
    reset_state,
    set_opportunity_report,
    set_position_report,
    set_active_orders,
    set_trading_statistics,
    set_fill_statistics,
    set_latency_statistics,
    set_volarb_model,
    append_update,
    append_update_batch,
    append_updates,
    inst_allow,
    inst_deny,
    denylist_set
} = ordersSlice.actions;

export const select_last_msg_timestamp = state =>
    state.orders.last_msg_timestamp;

export const select_order_trade_ratio = state =>
    state.orders.order_trade_ratio;

export const select_total_value_traded = state =>
    state.orders.total_value_traded;

export const select_total_pnl_rmb = state =>
    state.orders.total_pnl_rmb;

export const select_total_weighted_pnl_bps = state =>
    state.orders.total_weighted_pnl_bps;

export const select_num_orders = state =>
    state.orders.num_orders;

export const select_num_cancels = state =>
    state.orders.num_cancels;

export const select_num_trades = state =>
    state.orders.num_trades;

export const select_message_fees = state =>
    state.orders.message_fees;

export const select_broker_fees = state =>
    state.orders.broker_fees;

export const select_exchange_fees = state =>
    state.orders.exchange_fees;

export const select_positions = state =>
    state.orders.positions_rows;

export const select_position = (inst) => state =>
    (inst in state.orders.positions) ? state.orders.positions[inst] : empty_position(state.orders, inst)

export const select_all_updates = state =>
    state.orders.all_updates_rows;

export const select_active_orders = state =>
    state.orders.active_orders_rows;

export const select_trades = state =>
    state.orders.trades_rows;

export const select_market = state =>
    state.orders.market_rows;

export const select_volarb = state =>
    state.orders.volarb_rows;

export const select_inst_denied = (inst) => state =>
    state.orders.denylist.has(inst);

export const select_fill_statistics = state =>
    state.orders.fill_statistics;

export const select_latency_statistics = state =>
    state.orders.latency_statistics;

export default ordersSlice.reducer;
