import Box from '@mui/material/Box';

const renderLatency = (v) => {
    let ns = `${v.toLocaleString('en')}`;
    return (
        <div
            style={{
                fontSize: 10,
                width: "100%",
                textAlign: "right",
                color: 'secondary'
            }}
        >
            {ns}
        </div>
    );
}

export const LatencyColumn = (field, label) => {
    return {
        field: field,
        headerName: label,
        headerAlign: 'center',
        align: 'right',
        minWidth: 80,
        flex: 1,
        renderCell: (cellValues) => {
            let v = cellValues.value;
            if (typeof(v) === 'number') {
                return renderLatency(v);
            }
        }
    }
};
