import Box from '@mui/material/Box';

const renderPrice = (v) => {
    let text = v.price.toFixed(3);
    let decoration = '';
    let color='text.primary';
    if (typeof(v.qty) === 'number') {
        if (v.qty === 0) {
            color='text.primary';
        } else if (v.bought < v.price) {
            color='green'
        } else {
            color='red'
        }
    }

    if (v.side === 'ask') {
        decoration = 'overline red';
    } else if (v.side === 'bid') {
        decoration = 'underline blue';
    }
    return (
        <div
            style={{
                color: color,
                textDecoration: decoration,
                fontSize: 14,
                width: "100%",
                textAlign: "right"
            }}
        >
            {text}
        </div>
    );
}

export const PriceColumn = (field='price', label='Price') => {
    return {
        field: field,
        headerName: label,
        headerAlign: 'center',
        align: 'right',
        minWidth: 80,
        flex: 3,
        renderCell: (cellValues) => {
            let v = cellValues.value;
            if (typeof(v.price) !== 'undefined') {
                return renderPrice(v);
            } else if (typeof(v[0].price) !== 'undefined') {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    {
                        v.map((price, index) => {
                            return renderPrice(price)
                        })
                    }
                    </Box>
                );
            } else {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    {
                        JSON.stringify(typeof(v))
                    }
                    </Box>
                );
            }
        }
    }
};
