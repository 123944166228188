import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';

import Typography from '@mui/material/Typography';

import { styled } from "@mui/material/styles";

import {
    DataGrid
} from '@mui/x-data-grid';

interface GridCardInterface {
    expanded: bool,
    width: number,
    height: number,
    rows: any[],
    columns: GridColDef[],
    title: string,
    noRowsText: string
}

const CardContentNoPadding = styled(CardContent)(`
  padding-top: 0.2;
  padding-bottom: 0;
  padding-left: 0.5;
  padding-right: 0.5;
  &:last-child {
    padding-bottom: 0;
  }
`);

const GridCard = (props: GridCardInterface) => {
    const init_expanded = props.expanded ? true : false;
    const [expanded, set_expanded] = useState(init_expanded);
    const height = expanded ? props.height : '44px';
    const width = props.width;

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_expanded(event.target.checked);
    }

    const onClick = () => {
        set_expanded(!expanded);
        console.log("CLICKED");
    }

    return (
        <Card sx={{
            height: {height},
            width: {width},
            display: 'flex'
        }}>
          <CardContentNoPadding sx={{
              height: '100%',
              width: '100%',
              flex: '1 1 auto'
          }}>

            <Box
                sx={{
                    m: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                }}
                onClick={onClick}
            >

            <Checkbox
                checked={expanded}
                onChange={onChangeHandler}
                icon={<ArrowRight fontSize='medium'/>}
                checkedIcon={<ArrowDropDown fontSize='medium'/>}
                sx={{
                    p: 0
                }}
            />

            <Box sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
            <Typography gutterBottom variant="h6" component="span">{props.title}</Typography>

            <Typography
              sx={{
                color: 'text.secondary',
                marginLeft: '0.5em'
              }}
              gutterBottom variant="subtitle1" component="span">
              ({props.rows.length})</Typography>

            </Box>
            </Box>

            { expanded &&
            <DataGrid
              sx={{
                  height: 'calc(100% - 40px)',
                  p: 0
              }}
              components={{
                NoRowsOverlay: (params) => {
                  return <div style={{ color: 'gray', textAlign: 'center'}}>{props.noRowsText}</div>;
                }
              }}
              hideFooterPagination={props.hideFooterPagination}
              rows={props.rows}
              rowHeight={props.rowHeight ? props.rowHeight : 25}
              columns={props.columns}
              initialState={{
                  sorting: {
                      sortModel: [{ field: 'timestamp', sort: 'desc' }],
                  },
              }}
              getRowClassName={(params) => `local-theme--${params.row.status}`}
            />
            }
          </CardContentNoPadding>
        </Card>
    );
}

export default GridCard;
