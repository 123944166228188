import Box from '@mui/material/Box';

const renderVolatility = (v) => {
    let text = v.toFixed(3);
    let color='text.primary';
    if (v < 30.0) {
        color='text.primary';
    } else if (v < 100.0) {
        color='warning.main';
    } else {
        color='error.main';
    }

    return (
        <div
            style={{
                color: color,
                fontSize: 14,
                width: "100%",
                textAlign: "right"
            }}
        >
            {text}
        </div>
    );
}

export const VolatilityColumn = (field='volatility', label='Volatility') => {
    return {
        field: field,
        headerName: label,
        headerAlign: 'center',
        align: 'right',
        minWidth: 80,
        flex: 3,
        renderCell: (cellValues) => {
            let v = cellValues.value;
            return renderVolatility(v);
        }
    }
};
