import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './App.css';

import { routes, hidden_routes } from './routes';

import Top from './components/Top.js';
import Login from './views/Login.js';
import DataSource from './components/DataSource.js';

import { api_get } from './lib/actions.js';
import { useGoogleAuth } from './lib/googleAuth';

import {
    select_dark_mode,
} from './slices/prefsSlice';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const { isSignedIn, isInitialized, googleUser } = useGoogleAuth();

  const dark_mode = useSelector(select_dark_mode);

  const publisher_start = async (token) => {
      await api_get(token, '/publisher_start');
  };

  useEffect(() => {
      if (isInitialized && isSignedIn) {
          publisher_start(googleUser.tokenId);
      }
  }, [googleUser, isInitialized, isSignedIn]);

  if (!isInitialized) {
      return null;
  }

  if (!isSignedIn) {
      return <Login onLogin={publisher_start} />;
  }

  return (
      <ThemeProvider theme={dark_mode ? darkTheme : lightTheme}>
    <Router>
      <DataSource />
      <CssBaseline />

      <Top>
          <Routes>
          { routes.map(route =>
              <Route key={route.path} path={route.path} element={route.element} />)
          }
          { hidden_routes.map(route =>
              <Route key={route.path} path={route.path} element={route.element} />)
          }
          </Routes>
      </Top>
    </Router>
      </ThemeProvider>
  );
}

export default App;
