const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault("Asia/Hong_Kong");

export const timestamp_to_string = (timestamp) => {
    if (typeof(timestamp) !== 'number') {
        return "--:--:--.---";
    }

    const timestamp_ms = timestamp / 1000000;
    return dayjs.tz(timestamp_ms).format('HH:mm:ss.SSS');
}

export const now_to_string = () => {
    return dayjs.tz().format('HH:mm:ss.SSS');
}

/*
// Display a timestamp (raw XTP format)
// Raw data is eg.
//
//   20220331104952110

export const hhmmssfff_to_string = (hhmmssfff) => {
    const hh = Math.floor(hhmmssfff / 10000000);
    const mmssfff = hhmmssfff % 10000000;
    const mm = Math.floor(mmssfff / 100000);
    const ssfff = mmssfff % 100000;
    const ss = Math.floor(ssfff / 1000);
    const fff = ssfff % 1000;

    const zeroPad = (num, places) => {
        return String(num).padStart(places, '0')
    }

    const text = `${zeroPad(hh,2)}:${zeroPad(mm,2)}:${zeroPad(ss,2)}.${zeroPad(fff,3)}`;

    return text;
}

export const yyyymmddhhmmssfff_to_string = (timestamp) => {
    if (typeof(timestamp) !== 'number') {
        return "-:--:--.---";
    }

    const hhmmssfff = timestamp % 1000000000;
    return hhmmssfff_to_string(hhmmssfff);
}
*/
