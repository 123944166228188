
export const show_2dp = (value) => {
    const format = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };
    return `${value ? value.toLocaleString('en', format) : 0.00 }`;
}

export const show_rmb = (rmb) => {
    return `¥${show_2dp(rmb)}`;
}

