import { TimestampColumn } from '../components/GridColDefs/TimestampColumn.js';
import { TopicColumn } from '../components/GridColDefs/TopicColumn.js';
import { CountTradesColumn } from '../components/GridColDefs/CountTradesColumn.js';
import { LatencyColumn } from '../components/GridColDefs/LatencyColumn.js';
import { OidColumn } from '../components/GridColDefs/OidColumn.js';
import { CancelButtonColumn } from '../components/GridColDefs/CancelButtonColumn.js';
import { ChartColumn } from '../components/GridColDefs/ChartColumn.js';
import { InstDenyButtonColumn } from '../components/GridColDefs/InstDenyButtonColumn.js';
import { InstColumn } from '../components/GridColDefs/InstColumn.js';
import { SideColumn } from '../components/GridColDefs/SideColumn.js';
import { QtyColumn } from '../components/GridColDefs/QtyColumn.js';
import { PnlBpsColumn } from '../components/GridColDefs/PnlBpsColumn.js';
import { PnlRMBColumn } from '../components/GridColDefs/PnlRMBColumn.js';
import { PriceColumn } from '../components/GridColDefs/PriceColumn.js';
import { StatusColumn } from '../components/GridColDefs/StatusColumn.js';
import { VolatilityColumn } from '../components/GridColDefs/VolatilityColumn.js';
import { FillColumn } from '../components/GridColDefs/FillColumn.js';
import { ByColumn } from '../components/GridColDefs/ByColumn.js';

export const active_columns: GridColDef[] = (isDesktop) => [
    CancelButtonColumn(),
    TimestampColumn('timestamp', isDesktop),
    InstColumn,
    SideColumn,
    QtyColumn,
    PriceColumn(),
    LatencyColumn('internal_latency', 'Internal'),
    LatencyColumn('broker_latency', 'Broker'),
    FillColumn,
    OidColumn(),
    ByColumn
];

export const position_columns: GridColDef[] = [
    InstColumn,
    InstDenyButtonColumn(),
    QtyColumn,
    PnlRMBColumn('value_traded', 'Value Traded'),
    CountTradesColumn,
    VolatilityColumn('volatility', 'Volatility'),
    PriceColumn('last_prices', 'Last Prices'),
    PriceColumn('fair', 'Fair'),
    PnlRMBColumn('cash', 'Cash'),
    PnlRMBColumn('pnl', 'PnL'),
    PnlBpsColumn('bps', 'BPS')
];

export const market_columns: GridColDef[] = [
    InstColumn,
    ChartColumn,
    InstDenyButtonColumn(),
    VolatilityColumn('volatility', 'Volatility'),
];

export const open_columns: GridColDef[] = (isDesktop) => [
    TimestampColumn('timestamp', isDesktop),
    InstColumn,
    QtyColumn,
    PriceColumn(),
    StatusColumn,
    FillColumn,
    OidColumn(),
    ByColumn
];

export const turnaround_columns: GridColDef[] = (isDesktop) => [
    TimestampColumn('timestamp', isDesktop),
    InstColumn,
    QtyColumn,
    PriceColumn(),
    PnlRMBColumn('pnl_rmb', 'PnL'),
    PnlBpsColumn('pnl_bps', 'BPS'),
    FillColumn,
    OidColumn('oid', 'Oids'),
    ByColumn
];

export const trade_columns: GridColDef[] = (isDesktop) => [
    TimestampColumn('timestamp', isDesktop),
    InstColumn,
    SideColumn,
    QtyColumn,
    PriceColumn(),
    //PnlRMBColumn('pnl_rmb', 'PnL'),
    //PnlBpsColumn('pnl_bps', 'BPS'),
    OidColumn(),
    ByColumn
];

export const volarb_columns: GridColDef[] = (isDesktop) => [
    TimestampColumn('timestamp', isDesktop),
    InstColumn,
    PriceColumn('model_fair', 'Model Fair'),
    VolatilityColumn('model_vola', 'Volatility'),
    PnlBpsColumn('model_step_bps', 'Step BPS'),
    PnlBpsColumn('model_update_bps', 'Update BPS'),
    PriceColumn('model_exposure', 'Exposure'),
    OidColumn('model_max_position', 'Max Position'),
    PriceColumn('model_stop_loss', 'Stop Loss')
];

export const stream_columns: GridColDef[] = (isDesktop) => [
    TimestampColumn('timestamp', isDesktop),
    TopicColumn,
    OidColumn(),
    InstColumn,
    SideColumn,
    QtyColumn,
    PriceColumn(),
    StatusColumn,
    LatencyColumn('internal_latency', 'Internal'),
    LatencyColumn('broker_latency', 'Broker'),
    FillColumn,
    ByColumn
];

