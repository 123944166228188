import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import LoginButton from '../components/LoginButton';

import './Login.css';

const Login = (params) => {
    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            display: 'flex',
            flexDirection: 'column'
        }}>
          <AppBar
            position="sticky"
            sx={{
              backgroundColor: "#090612",
              width: 1
            }}
          >
            <LoginButton onLogin={params.onLogin}/>
          </AppBar>
          <Box
            sx={{
                flexGrow: 1
            }}
          >
            <iframe
            width="100%" height="100%"
            src="https://www.youtube.com/embed/_YC3sTbAPcU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Box>
        </Box>
    );
};

export default Login;
