import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { routes } from '../../routes';

const NavigationTabs = (props) => {
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue) => {
    navigate(newValue);
  };

  const is_path = (route) => {
      return route.path === props.pathname;
  }

  const value = routes.some(is_path) ? props.pathname : "/";

  return (
    <Box sx={{ position: 'sticky', flex: 2 }}>
      <Paper
          elevation={3}
          sx={{ mx: 1 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
              sx: { height: 3, mb: 1 },
          }}
        >
          { routes.map(route =>
              <Tab key={route.path} label={route.label} value={route.path} />)
          }
        </Tabs>
      </Paper>
    </Box>
  );
}

export default NavigationTabs;
