import React from 'react';
import Button from '@mui/material/Button';

import { useGoogleAuth } from '../lib/googleAuth';

const LoginButton = (params) => {

    const { signIn } = useGoogleAuth();

    const handle_login = async () => {
        const googleUser = await signIn();

        if (typeof(googleUser) !== 'undefined') {
            console.log(`Logged in authToken ${googleUser.authToken}`);
            console.log(`Logged in tokenId ${googleUser.tokenId}`);
            if (params.onLogin) {
                params.onLogin(googleUser.tokenId);
            }
        }
    }

    return (
        <Button
            color="secondary"
            onClick={handle_login}>Login</Button>
      );
};

export default LoginButton;
