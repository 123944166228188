import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { io } from "socket.io-client";

import { config } from '../lib/config.js';
import {
    api_get,
    send_asset_query,
    send_config_get,
    send_config_get_all,
    send_denylist_get,
} from '../lib/actions.js';
import { useGoogleAuth } from '../lib/googleAuth';

import { config_get } from '../slices/configSlice';
import {
    reset_state,
    //append_update,
    append_update_batch,
    append_updates,
    //set_opportunity_report,
    set_position_report,
    set_active_orders,
    set_trading_statistics,
    set_volarb_model,
} from '../slices/ordersSlice';

const enc = new TextDecoder('utf-8')

export const DataSource = (props) => {
    const dispatch = useDispatch();
    const { isSignedIn, googleUser } = useGoogleAuth();

    const on_off = useSelector(config_get("config.control.on_off"));

    const get_config_all = useCallback(async () => {
        send_config_get_all(googleUser.tokenId);
    }, [googleUser.tokenId]);

    const get_balance = useCallback(async () => {
        send_asset_query(googleUser.tokenId);
    }, [googleUser.tokenId]);

    useEffect(() => {
        if (!config.demo) {
            const interval = setInterval(() => get_balance(), 300 * 1000);
            return () => clearInterval(interval)
        }
    }, [get_balance]);

    const get_action_budget = useCallback(async () => {
        send_config_get(googleUser.tokenId,
            "config.control.action_budget");
    }, [googleUser.tokenId]);

    useEffect(() => {
        if (!config.demo) {
            console.log("Starting getting action_budget");
            const interval = setInterval(() => on_off && get_action_budget(), 10 * 1000);
            return () => {
                console.log("Stopping getting action budget");
                clearInterval(interval)
            };
        }
    }, [on_off, get_action_budget]);

    const get_denylist = useCallback(async () => {
        send_denylist_get(googleUser.tokenId);
    }, [googleUser.tokenId]);

    useEffect(() => {
        if (!config.demo) {
            const interval = setInterval(() => on_off && get_denylist(), 30 * 1000);
            return () => clearInterval(interval)
        }
    }, [on_off, get_denylist]);

    const get_active_orders = useCallback(async () => {
        const response = await api_get(googleUser.tokenId, '/get_active_orders');
        //console.log(`GET /get_active_orders returned ${JSON.stringify(response)}`);
        dispatch(set_active_orders(response.data));
    }, [dispatch, googleUser.tokenId]);

    useEffect(() => {
        if (!config.demo) {
            const interval = setInterval(() => on_off && get_active_orders(), 10 * 1000);
            return () => clearInterval(interval)
        }
    }, [on_off, get_active_orders]);

    const get_volarb = async () => {
        const model_response = await api_get(googleUser.tokenId, '/get_volarb_model');
        dispatch(set_volarb_model(model_response.data));
    }

    useEffect(() => {
        if (!config.demo) {
            console.log("Starting getting volarb");
            const interval = setInterval(() => on_off && get_volarb(), 15 * 1000);
            return () => {
                console.log("STOPPING getting volarb");
                clearInterval(interval);
            };
        }
    }, [on_off, get_volarb]);


    useEffect(() => {
        const get_position_report = async () => {
            const response = await api_get(googleUser.tokenId, '/get_position_report');
            //console.log(`GET /get_position_report returned ${JSON.stringify(response)}`);
            dispatch(set_position_report(response.data.data));
        }

        const get_order_updates = async () => {
            const response = await api_get(googleUser.tokenId, '/get_order_updates');
            //console.log(`GET /get_order_updates: response: ${JSON.stringify(response)}`);
            while (response.data.length > 0) {
                const chunk = response.data.splice(0, 100);
                await dispatch(append_updates(chunk));
            }
        }

        const get_trades = async () => {
            const response = await api_get(googleUser.tokenId, '/get_trades');
            dispatch(append_updates(response.data));
        }

        const get_trading_statistics = async () => {
            const response = await api_get(googleUser.tokenId, '/get_trading_statistics');
            dispatch(set_trading_statistics(response.data.data));
        }

        const init = async () => {
            console.log("RESET STATE");
            dispatch(reset_state());

            if (isSignedIn && googleUser && !config.demo) {

                get_config_all();
                get_balance();
                get_position_report();
                get_trading_statistics();
                get_active_orders();
                get_trades();
                //get_order_updates();
                get_denylist();
                get_volarb();

                let socket = io(config.UPDATE_WS, { path: "/ws/socket.io/", transports: ['websocket', 'polling'] });

                socket.on("connect", () => { console.log("WS Connected", socket.id) });
                socket.on("response", () => { console.log("WS Response", socket.id) });
                socket.on("disconnect", () => {
                    console.log("WS Disconnected, reconnecting...");
                    socket = io(config.UPDATE_WS, { path: "/ws/socket.io/", transports: ['websocket', 'polling'] });
                });

                socket.on("message", message => {
                    //console.log("WS Message:", message);
                    const batch = JSON.parse(message);
                    if (batch) {
                        dispatch(append_update_batch(batch));
                    }
                });

                return () => {
                    socket.disconnect();
                }
            }
        }

        init();
    }, [isSignedIn, googleUser, dispatch, get_balance, get_denylist, get_active_orders, get_volarb]);

    return null;
}

export default DataSource;
