import { get_underlying } from './underlying';

var stock_list = require('../szse-stock-list-en.json')

/*
        "industry": "A Agriculture, forestry, animal husbandry and fishery",
        "industry": "B Mining",
        "industry": "C Manufacturing",
        "industry": "D Water, Electricity and Gas",
        "industry": "E Construction Industry",
        "industry": " F Wholesale and retail",
        "industry": "G Transportation and Warehousing",
        "industry": "H Accommodation and Catering",
        "industry": "I Information Technology",
        "industry": "J Finance",
        "industry": "K Real Estate",
        "industry": "L Business Service",
        "industry": "M Scientific Research Services",
        "industry": "N Public Environmental Protection",
        "industry": "O Resident Service",
        "industry": "P Education",
        "industry": "Q Sanitation",
        "industry": "R Cultural Communication",
        "industry": "S Comprehensive",
*/

const industry_icons = {
    "A": "🚜",
    "B": "⛏️",
    "C": "🏭",
    "D": "⚡",
    "E": "🏗️",
    "F": "🏗️",
    "G": "🏗️",
    "H": "🏗️",
    "I": "📱",
    "J": "💰",
    "K": "🏘️",
    "L": "🗃️",
    "M": "🧪",
    "N": "🧪",
    "O": "O",
    "P": "🧑‍🎓",
    "Q": "Q",
    "R": " 📡",
    "S": "S"
}

export const get_stock = (inst) => {
    if (typeof(stock_list[inst]) === "undefined") {
        const underlying = get_underlying(inst);
        if (typeof(underlying) !== "string") {
            return null;
        }
        return get_stock(underlying);
    }

    return stock_list[inst];
}

export const get_industry = (inst) => {
    const stock = get_stock(inst);
    return stock ? stock["industry"] : null;
}

export const get_industry_code = (inst) => {
    const industry = get_industry(inst);
    if (typeof(industry) !== "string" || industry.length === 0) {
        return "";
    }
    return get_industry(inst)[0];
}

export const get_industry_icon = (inst) => {
    const code = get_industry_code(inst);
    const icon = industry_icons[code];
    if (typeof(icon) !== "undefined") {
        return icon;
    }
    return code;
}
