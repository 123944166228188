export const pad6 = (inst) => String(inst).padStart(6, '0');

export const eastmoney_chart_img = (inst) =>
    `https://webquotepic.eastmoney.com/GetPic.aspx?imageType=r&nid=0.${pad6(inst)}`;

export const eastmoney_url = (inst) =>
    `http://quote.eastmoney.com/sz${pad6(inst)}.html`;

export const eastmoney_link = (inst) =>
    <a href={eastmoney_url(inst)} target="_blank" rel="noopener noreferrer">{pad6(inst)}</a>;

export const eastmoney_chart_link = (inst) =>
    <a href={eastmoney_url(inst)} target="_blank" rel="noopener noreferrer"><img height="200" src={eastmoney_chart_img(inst)}/></a>;
