import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import {
    send_inst_allow,
    send_inst_deny,
} from '../lib/actions.js';

import { useGoogleAuth } from '../lib/googleAuth';

interface InstProps {
    inst: number;
    denied: bool;
}

export const InstDenyButton = (props: InstProps) => {

    const { googleUser } = useGoogleAuth();
    const { inst, denied } = props;

    const [ disabled, set_disabled ] = useState(false);

    const handle_deny = async () => {
        set_disabled(true);
        setTimeout(() => set_disabled(false), 3000);
        await send_inst_deny(googleUser.tokenId, inst);
    }

    const handle_allow = async () => {
        set_disabled(true);
        setTimeout(() => set_disabled(false), 3000);
        await send_inst_allow(googleUser.tokenId, inst);
    }

    if (denied) {
        return (
            <IconButton 
                aria-label="allow"
                color = "error"
                size = "small"
                disabled={disabled}
                onClick={handle_allow}
            >
                <NotInterestedIcon/>
            </IconButton>
        );
    } else {
        return (
            <IconButton 
                aria-label="deny"
                color = "primary"
                size = "small"
                disabled={disabled}
                onClick={handle_deny}
            >
                <CheckCircleOutlineIcon/>
            </IconButton>
        );
    }
};
