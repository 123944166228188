import { config } from './config.js';

import store from '../store';

import {
    config_set,
    config_set_all,
} from '../slices/configSlice';

import {
    inst_allow,
    inst_deny,
    denylist_set,
    set_fill_statistics,
    set_latency_statistics,
} from '../slices/ordersSlice';

import {
    set_balance,
} from '../slices/xtpSlice';

const axios = require('axios');

const backend_api_get = async (api_url, token, path, data='') => {
    let url = `${api_url}${path}`;

    if (data !== '') {
        url = `${url}/${data}`;
    }

    //console.log(`api_get GET ${url}, token=${token}`);

    return axios({
        'method': 'get',
        'url': url,
        'headers': {
            "Authorization": "Bearer " + token,
        'timeout': 3000
        }
    });
}

export const api_get = async (token, path, data='') => {
    return backend_api_get(config.ORDER_API_URL, token, path, data);
}

export const algo_api_get = async (token, path, data='') => {
    return backend_api_get(config.ALGO_API_URL, token, path, data);
}

export const backend_api_post = async (api_url, token, path, data) => {
    let url = `${api_url}${path}`;
    //console.log(`api_post POST ${url}, token=${token}`);

    return axios({
        'method': 'post',
        'url': url,
        'headers': {
            "Authorization": "Bearer " + token,
        },
        'data': data,
        'timeout': 3000
    });
}

export const api_post = async (token, path, data) => {
    return backend_api_post(config.ORDER_API_URL, token, path, data);
}

export const algo_api_post = async (token, path, data) => {
    return backend_api_post(config.ALGO_API_URL, token, path, data);
}

export const send_query_by_xtp_id = async (token, xtp_id) => {
    return api_get(token, '/query', xtp_id);
}

export const send_cancel = async (token, oid) => {
    return api_get(token, '/cancel', oid);
}

export const send_cancel_by_xtp_id = async (token, xtp_id) => {
    return api_get(token, '/cancel_by_xtp_id', xtp_id);
}

export const send_insert = async (token, order) => {
    return api_post('/insert', order, token);
}

export const send_asset_query = async (token) => {
    const response = await api_get(token, '/asset');

    if (typeof response.data !== 'undefined') {
        //console.log(`ASSET ${JSON.stringify(response.data)}`);
        if (typeof(response.data.total_asset !== 'undefined')) {
          store.dispatch(set_balance(response.data.total_asset));
        }
    }

    return response;
}

export const send_fill_statistics_query = async (token) => {
    const response = await api_get(token, '/get_fill_statistics');

    if (typeof response.data !== 'undefined') {
        console.log(`FillStatistics ${JSON.stringify(response.data)}`);
        if (typeof(response.data.data) !== 'undefined') {
            store.dispatch(set_fill_statistics(response.data.data));
        }
    }

    return response;
}

export const send_latency_statistics_query = async (token) => {
    const response = await api_get(token, '/get_latency_statistics');

    if (typeof response.data !== 'undefined') {
        console.log(`LatencyStatistics ${JSON.stringify(response.data)}`);
        if (typeof(response.data.data) !== 'undefined') {
            store.dispatch(set_latency_statistics(response.data.data));
        }
    }

    return response;
}

export const send_config_get = async (token: string, field: string) => {
    const data = {
        'field': field
    };
    //console.log(`Sending config_get: ${JSON.stringify(data)}`);
    const response = await algo_api_post(token, '/config_get', data);
    //console.log(`Received config_get response for ${field}: ${JSON.stringify(response)}`);

    const value = response['data']['value'];
    if (typeof(value) !== 'undefined') {
        //console.log(`Updated ${field} to ${value}`)
        store.dispatch(config_set([field, value]));
    }

    return response;
}

export const send_config_get_all = async (token: string) => {
    const data = {
    };
    //console.log(`Sending config_get_all: ${JSON.stringify(data)}`);
    const response = await algo_api_post(token, '/config_get_all', data);
    //console.log(`Received config_get_all response ${JSON.stringify(response)}`);

    const config = response['data']['value']['data'];
    if (typeof(config) !== 'undefined') {
        console.log(`Got config ${JSON.stringify(config)}`)
        store.dispatch(config_set_all(config));
    }

    return response;
}

export const send_config_set = async (token: string, field: string, value: any) => {
    const data = {
        'field': field,
        'value': value
    };
    //console.log(`Sending config_set: ${JSON.stringify(data)}`);
    const response = await algo_api_post(token, '/config_set', data);
    //console.log(response);

    store.dispatch(config_set([field, value]));
}

export const send_inst_deny = async (token: string, inst: int) => {
    const data = {
        'inst': inst
    };
    //console.log(`Sending inst_deny: ${JSON.stringify(data)}`);
    const response = await algo_api_post(token, '/inst_deny', data);
    //console.log(`Received inst_deny response for ${inst}: ${JSON.stringify(response)}`);

    const status = response['data']['status'];
    if (typeof(status) !== 'undefined') {
        //console.log(`Denied ${inst}`)
        store.dispatch(inst_deny(inst));
    }

    return response;
}

export const send_inst_allow = async (token: string, inst: int) => {
    const data = {
        'inst': inst
    };
    //console.log(`Sending inst_allow: ${JSON.stringify(data)}`);
    const response = await algo_api_post(token, '/inst_allow', data);
    //console.log(`Received inst_allow response for ${inst}: ${JSON.stringify(response)}`);

    const status = response['data']['status'];
    if (typeof(status) !== 'undefined') {
        //console.log(`Allowed ${inst}`)
        store.dispatch(inst_allow(inst));
    }

    return response;
}

export const send_denylist_get = async (token: string) => {
    const data = {
    };
    //console.log(`Sending denylist_get: ${JSON.stringify(data)}`);
    const response = await algo_api_post(token, '/denylist_get', data);
    //console.log(`Received denylist_get response: ${JSON.stringify(response)}`);

    const value = response['data']['value'];
    if (typeof(value) !== 'undefined') {
        //console.log(`Denylist ${value}`)
        store.dispatch(denylist_set(value));
    }

    return response;
}
