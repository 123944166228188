import { CancelButton } from '../CancelButton.js';

export const CancelButtonColumn = (field='oid_cancel', label='') => {

    return {
        field: field,
        headerName: label,
        headerAlign: 'center',
        align: 'center',
        minWidth: 20,
        flex: 1,
        renderCell: (cellValues) => {
            let oid = cellValues.value;

            return (
              <CancelButton
                oid={oid}
              />
            );
        }
    }
};
