import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import InstCard from '../components/InstCard.js';

import useIsDesktop from '../lib/isDesktop';

import {
    select_market,
} from '../slices/ordersSlice';

import {
    market_columns
} from '../components/Columns.js';

const Market = (props) => {
    const isDesktop = useIsDesktop();

    const market = useSelector(select_market);

    const visible_height = 'calc(100vh - 150px)';
    const half_visible_height = 'calc(50vh - 75px)';

    return (
        <Box
            sx={{
                width: '100%',
                height: visible_height,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignContent: "stretch"
            }}
            flexWrap="wrap"
            overflow="auto"
            flex={1}
            p={2}
        >
        { market.map((instDetails) =>
            <InstCard
                expanded={true}
                height={600}
                width={320}
                details={instDetails}
            />)
        }

        </Box>

    );
};

export default React.memo(Market);
