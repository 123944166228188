import React, { useState } from 'react';

import { RenderInst } from '../InstLabel';

export const InstColumn = {
    field: 'inst',
    headerName: 'Inst',
    headerAlign: 'center',
    align: 'center',
    minWidth: 64,
    flex: 2,
    renderCell: (cellValues) => {
        return RenderInst(cellValues.value);
    }
}
