
export const object_values = (o) => {
    return Object.keys(o).map((key) => o[key]);
}

export const object_bids = (o) => {
    return Object.keys(o)
        .filter((key) => typeof(o[key].bid) !== 'undefined')
        .map((key) => o[key].bid);
}
