import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import GridCard from '../components/GridCard.js';

import useIsDesktop from '../lib/isDesktop';

import {
    select_all_updates,
} from '../slices/ordersSlice';

import { sx_rows_by_status } from '../styles/order-row.js';

import {
    stream_columns,
} from '../components/Columns.js';

const OrderUpdates = (props) => {
    const isDesktop = useIsDesktop();

    const all_updates = useSelector(select_all_updates);

    const visible_height = 'calc(100vh - 150px)';

    return (
      <Box sx={sx_rows_by_status}>

        <GridCard
            expanded={true}
            width='100%'
            height={visible_height}
            rows={all_updates}
            columns={stream_columns(isDesktop)}
            title="Order Updates"
            noRowsText="No orders"
        />

      </Box>
    );
};

export default React.memo(OrderUpdates);
