import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dark_mode: true,
};

export const prefsSlice = createSlice({
    name: 'prefs',
    initialState,
    reducers: {
        set_dark_mode: (state, action) => {
            state.dark_mode = action.payload;
        },
    }
});

export const {
    set_dark_mode,
} = prefsSlice.actions;

export const select_dark_mode = state =>
    state.prefs.dark_mode;

export default prefsSlice.reducer;
