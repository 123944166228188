import Box from '@mui/material/Box';

const render_by = (o) => {
    return (
      <div>
        { o.insert_user !== o.cancel_user &&
        <span
          style={{
              fontWeight: 'lighter'
          }}
        >{o.insert_user}</span>
        }
        { o.cancel_user !== '' &&
        <>
        { o.insert_user !== o.cancel_user &&
          <>
        <span
          style={{
              fontWeight: 'bold',
              color: 'red',
          }}
        >&nbsp;x</span>
        <span
          style={{
              textDecoration: 'double line-through red',
          }}
        >&nbsp;</span>
          </>
        }
        <span
          style={{
              textDecoration: 'double line-through red',
              fontWeight: 'lighter'
          }}
        >{o.cancel_user}</span>
        </>
        }
      </div>
    );
}

export const ByColumn = {
    field: 'by',
    headerName: 'By',
    headerAlign: 'center',
    align: 'center',
    width: 150,
    flex: 2,
    renderCell: (cellValues) => {
        let v = cellValues.value;
        if (typeof(v.insert_user) !== 'undefined') {
            return render_by(v);
        } else {
            return (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                {
                    v.map((by, index) => {
                        return <div key={index}>{render_by(by)}</div>
                    })
                }
                </Box>
            );
        }
    }
};
