import Box from '@mui/material/Box';

export const OidColumn = (field='oid', label='Oid') => {
    return {
        field: field,
        headerName: label,
        headerAlign: 'center',
        align: 'center',
        minWidth: 70,
        flex: 1,
        renderCell: (cellValues) => {
            let v = cellValues.value;

            if (typeof(v) === 'number') {
                return (<div>{v}</div>);
            } else {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    { v.map((oid, index) => {
                        return <div key={index}>{oid}</div>
                      })
                    }
                    </Box>
                );
            }
        }
    }
};
