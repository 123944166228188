import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { timestamp_to_string, now_to_string } from '../../lib/timestamp.js';

import {
    select_last_msg_timestamp,
} from '../../slices/ordersSlice';
import {
    select_dark_mode,
} from '../../slices/prefsSlice';

const CurrentTimestamp = () => {
    const last_msg_timestamp = useSelector(select_last_msg_timestamp);

    const [now, set_now] = useState("--:--:--.---");

    useEffect(() => {
        const interval = setInterval(() => {
            set_now(now_to_string());
        }, 666);
        return () => clearInterval(interval);
    }, []);

    const dark_mode = useSelector(select_dark_mode);
    const paper_bg = dark_mode ? 'black' : 'khaki';

    return (
      <Box>
      <Paper
          elevation={3}
          sx={{
              m: 1,
              p: 0.5,
              backgroundColor: paper_bg,
          }}
      >
        <TextField
          id="top-last-msg-timestamp"
          label="Now"
          size="small"
          InputProps={{
            readOnly: true,
            textalign: 'center',
          }}
          sx={{
            width: 130,
            input: {
                color: 'gray',
            }
          }}
          value={now}
        />
        <TextField
          id="top-last-msg-timestamp"
          label="Last Message"
          size="small"
          InputProps={{
            readOnly: true,
            textalign: 'center',
          }}
          sx={{
            width: 130,
          }}
          value={timestamp_to_string(last_msg_timestamp)}
        />
      </Paper>
      </Box>
    );
}

export default CurrentTimestamp;
