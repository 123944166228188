import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import GridCard from '../components/GridCard.js';

import useIsDesktop from '../lib/isDesktop';

import {
    select_active_orders,
    select_trades,
    select_positions,
} from '../slices/ordersSlice';

import { sx_rows_by_status } from '../styles/order-row.js';

import {
    active_columns,
    position_columns,
    trade_columns
} from '../components/Columns.js';

const Trades = (props) => {
    const isDesktop = useIsDesktop();

    const active_orders = useSelector(select_active_orders);
    const trades = useSelector(select_trades);
    const positions = useSelector(select_positions);

    const visible_height = 'calc(100vh - 150px)';
    const half_visible_height = 'calc(50vh - 75px)';

    return (
      <Box sx={sx_rows_by_status}>

        { isDesktop &&
        <Box sx={{
            width: '100%',
            height: visible_height,
            display: 'flex',
            flexDirection: 'row'
        }}>

          <Box sx={{
            width: '50%',
            height: visible_height,
            display: 'flex',
            flexDirection: 'column'
          }}>

            <GridCard
              expanded={true}
              height={half_visible_height}
              width='100%'
              rows={active_orders}
              columns={active_columns(isDesktop)}
              title="Active Orders"
              noRowsText="No active orders"
            />

            <GridCard
                expanded={true}
                height={half_visible_height}
                width='100%'
                rows={trades}
                //rowHeight={42}
                columns={trade_columns(isDesktop)}
                title="Trades"
                noRowsText="No trades"
            />

          </Box>

        <GridCard
            expanded={true}
            height={visible_height}
            width='50%'
            rows={positions}
            rowHeight={42}
            columns={position_columns}
            title="Positions"
            noRowsText="No positions"
        />
        </Box>
        }

        { !isDesktop &&
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>

        <GridCard
            expanded={false}
            height={visible_height}
            width='100%'
            rows={active_orders}
            columns={active_columns(isDesktop)}
            title="Active Orders"
            noRowsText="No active orders"
        />

        <GridCard
            expanded={true}
            height={visible_height}
            width='100%'
            rows={trades}
            //rowHeight={42}
            columns={trade_columns(isDesktop)}
            title="Trades"
            noRowsText="No trades"
        />

        <GridCard
            expanded={false}
            height={visible_height}
            width='100%'
            rows={positions}
            rowHeight={42}
            columns={position_columns}
            title="Positions"
            noRowsText="No positions"
        />

        </Box>
        }


      </Box>
    );
};

export default React.memo(Trades);
