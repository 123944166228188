import Box from '@mui/material/Box';

const renderCountTrades = (v) => {
    let count_trades = `${v}`;
    return (
        <div
            style={{
                fontSize: 12,
                width: "100%",
                textAlign: "right",
                color: 'gray'
            }}
        >
            {count_trades}
        </div>
    );
}

export const CountTradesColumn = {
    field: 'count_trades',
    headerName: '#',
    headerAlign: 'center',
    align: 'right',
    minWidth: 40,
    flex: 0.5,
    renderCell: (cellValues) => {
        let v = cellValues.value;
        if (typeof(v) === 'number') {
            return renderCountTrades(v);
        } else {
            return (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                {
                    v.map((n, index) => {
                        return renderCountTrades(n)
                    })
                }
                </Box>
            );
        }
    }
};
