import { InstDenyButton } from '../InstDenyButton.js';

export const InstDenyButtonColumn = (field='denied', label='Allow') => {

    return {
        field: field,
        headerName: label,
        headerAlign: 'center',
        align: 'center',
        minWidth: 10,
        flex: 1,
        renderCell: (cellValues) => {
            let inst = cellValues.value.inst;
            let denied = cellValues.value.denied;
            return (
              <InstDenyButton
                inst={inst}
                denied={denied}
              />
            );
        }
    }
};
