import { darken, lighten } from '@mui/material/styles';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export const sx_rows_by_status = {
  width: 1,
  '& .local-theme--OnMarket': {
    bgcolor: (theme) =>
      getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
  },
  '& .local-theme--Complete': {
    bgcolor: (theme) =>
      getBackgroundColor(theme.palette.success.main, theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
    },
  },
  '& .local-theme--Cancelled': {
    bgcolor: (theme) =>
      getBackgroundColor(theme.palette.error.light, theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor(
          theme.palette.error.light,
          theme.palette.mode,
        ),
    },
  },
  '& .local-theme--Pending': {
    bgcolor: (theme) =>
      getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
    },
  },
  '& .local-theme--Error': {
    bgcolor: (theme) =>
      getBackgroundColor(theme.palette.error.main, theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
    },
  },
};
