import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { show_rmb } from '../lib/number';

import {
    send_asset_query,
} from '../lib/actions.js';

import { useGoogleAuth } from '../lib/googleAuth';

import {
    select_num_orders,
    select_num_trades,
    select_num_cancels,
    select_message_fees,
    select_broker_fees,
    select_exchange_fees,
} from '../slices/ordersSlice';

/*
 {"account_type":"normal","balance":0.0,"buying_power":500040.23,"capital_asset":0.0,"company_cur_risk_degree":0.0,"deposit_withdraw":0.0,"exchange_cur_risk_degree":0.0,"force_freeze_amount":0.0,"frozen_exec_cash":0.0,"frozen_exec_fee":0.0,"frozen_margin":0.0,"fund_buy_amount":0.0,"fund_buy_fee":0.0,"fund_cancel_data_charges":0.0,"fund_order_data_charges":0.0,"fund_sell_amount":0.0,"fund_sell_fee":0.0,"orig_balance":0.0,"pay_later":0.0,"preadva_pay":0.0,"preferred_amount":0.0,"repay_stock_aval_balance":0.0,"security_asset":0.0,"total_asset":500040.23,"trade_netting":0.0,"withholding_amount":0.0}
 */

const Assets = (props) => {
    const { googleUser } = useGoogleAuth();

    const [have_response, set_have_response] = useState(false);
    const [response_dict, set_response_dict] = useState({});

    const num_orders = useSelector(select_num_orders);
    const num_trades = useSelector(select_num_trades);
    const num_cancels = useSelector(select_num_cancels);
    const message_fees = useSelector(select_message_fees);
    const broker_fees = useSelector(select_broker_fees);
    const exchange_fees = useSelector(select_exchange_fees);

    const getResponse = async() => {
        const response = await send_asset_query(googleUser.tokenId);
        set_have_response(true);
        set_response_dict(response.data);
    }

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
        }}>

        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="span">Fees</Typography>
          </CardContent>
          <CardContent>
            <table>
            <tbody>
            <tr><th align="left">Message Fees</th><td>{show_rmb(message_fees)}</td></tr>
            <tr><th align="left">Broker Fees</th><td>{show_rmb(broker_fees)}</td></tr>
            <tr><th align="left">Exchange Fees</th><td>{show_rmb(exchange_fees)}</td></tr>
            </tbody>
            </table>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="span">Assets</Typography>
          </CardContent>
          <CardActions>
            { !have_response &&
              <Button
                variant="contained"
                color="info"
                onClick={getResponse}
              >Update Asset Balance</Button>
            }
          </CardActions>
          { have_response &&
            <CardContent>
              <table>
              <tbody>
              { Object.entries(response_dict).map(([key, value]) =>
                  <tr key={key} align="left"><th>{key}</th><td>{value}</td></tr>
                )
              }
              </tbody>
              </table>
            </CardContent>
          }
        </Card>

      </Box>
    );
}

export default Assets;
