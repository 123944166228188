
export const control_template = {
    "config.control.on_off": {
        input_type: "binary",
        label_set_true: "Start",
        color_set_true: "success",
        label_set_false: "Stop",
        color_set_false: "error",
    },
    "config.control.sell_only": {
        input_type: "switch",
        label: "Sell only",
        color: "primary",
    },
    "config.control.trade_out": {
        input_type: "switch",
        label: "Trade out",
        color: "primary",
    },
    "config.control.external_hitlift": {
        input_type: "switch",
        label: "Prep HitLift",
        color: "secondary",
    },
    "config.control.max_positions": {
        input_type: "int",
        label: "Max Positions",
        color: "info",
        min: 0,
        max: 50,
        step: 1,
    },
    "config.control.action_budget": {
        input_type: "int",
        label: "Action budget",
        color: "info",
        min: 0,
        max: 5000,
        step: 100,
    },
    "config.control.min_actions_to_buy": {
        input_type: "int",
        label: "Min Actions to Buy",
        color: "info",
        min: 0,
        max: 5000,
        step: 10,
    },
    "config.params.router.max_budget": {
        input_type: "double",
        label: "Max Budget",
        color: "info",
        min: 5000,
        max: 500000,
        step: 1000,
    },
    "config.params.algo.limits.budget_per_inst": {
        "input_type": "double",
        "label": "Budget per Instrument",
        "default": 0.10,
        "min": 0.01,
        "max": 1.0,
        "step": 0.01
    },
    "config.control.max_loss_per_inst": {
        input_type: "int",
        label: "Max Loss per Instrument",
        color: "info",
        min: 0,
        max: 50000,
        step: 100,
    },
}

export const volarb_template = {
    "config.params.algo.volarb.update_dist_mult": {
        input_type: "double",
        label: "Update dist multiplier",
        min: 0.1,
        max: 1.0,
        step: 0.1,
    },
    "config.params.algo.volarb.max_bid_dist": {
        input_type: "double",
        label: "Max Bid dist",
        min: 0.0,
        max: 1.0,
        step: 0.1,
    },
    "config.params.algo.volarb.spread_steps": {
        input_type: "double",
        label: "Spread Steps",
        min: 0.0,
        max: 10.0,
        step: 0.1,
    },
    "config.params.algo.volarb.momentum_window_s": {
        input_type: "double",
        label: "Momentum window (s)",
        min: 0.0,
        max: 1800.0,
        step: 1.0,
    },
    "config.params.algo.volarb.min_bid_value": {
        input_type: "double",
        label: "Min Bid Value",
        min: 0.0,
        max: 1000000.0,
        step: 1000.0,
    },
    "config.params.algo.volarb.min_bid_momentum": {
        input_type: "double",
        label: "Min Bid momentum",
        min: -1.0,
        max: 1.0,
        step: 0.0001,
    },
    "config.params.algo.volarb.max_ask_momentum": {
        input_type: "double",
        label: "Max Ask momentum",
        min: -1.0,
        max: 1.0,
        step: 0.0001,
    },
    "config.params.algo.volarb.size_mult": {
        input_type: "double",
        label: "Size multiplier",
        min: 0.1,
        max: 10.0,
        step: 1.0,
    }
}

export const quant_hitlift_template = {
    "config.params.algo.quant_hitlift.buy_threshold": {
        input_type: "double",
        label: "Buy Threshold",
        min: 0.0,
        max: 10.0,
        step: 0.1,
    },
    "config.params.algo.quant_hitlift.sell_threshold": {
        input_type: "double",
        label: "Sell Threshold",
        min: 0.0,
        max: 10.0,
        step: 0.1,
    },
    "config.params.algo.quant_hitlift.max_spread": {
        input_type: "double",
        label: "Max Spread",
        min: 0.0,
        max: 0.01,
        step: 0.0001,
    },
    "config.params.algo.quant_hitlift.take_profit": {
        input_type: "double",
        label: "Take Profit",
        min: 0.0,
        max: 0.1,
        step: 0.0001,
    },
    "config.params.algo.quant_hitlift.take_loss": {
        input_type: "double",
        label: "Take Loss",
        min: 0.0,
        max: 0.1,
        step: 0.0001,
    }
}

export const algo_template = {
    "config.params.algo.min_risk_factor": {
        input_type: "double",
        label: "Min Risk Factor",
        min: 0.1,
        max: 2.0,
        step: 0.1,
    },
    "config.params.algo.forest_model_mult": {
        input_type: "double",
        label: "Forest Model Mult",
        min: -10.0,
        max: 10.0,
        step: 0.1,
    },
    "config.params.algo.quant_model_mult": {
        input_type: "double",
        label: "Quant Model Mult",
        min: -10.0,
        max: 10.0,
        step: 0.1,
    },
}

export const limit_template = {
    "config.params.algo.limits.budget_per_inst": {
        "input_type": "double",
        "label": "Budget per Instrument",
        "default": 0.10,
        "min": 0.01,
        "max": 1.0,
        "step": 0.01
    },
}

export const old_limit_template = {
    "config.params.algo.limits.budget_per_inst": {
        "input_type": "double",
        "label": "Budget per Instrument",
        "default": 0.10,
        "min": 0.01,
        "max": 1.0,
        "step": 0.01
    },
    "config.params.algo.limits.min_order_value": {"input_type": "double", "label": "Min Order Value", "default": 25000.0, "min": 10000.0, "max": 50000.0, "step": 1000.0 },
    "config.params.algo.limits.backing_ratio": {"input_type": "double", "label": "Backing Ratio", "default": 0.5, "min": 0.0, "max": 5.0, "step": 0.1 },
    "config.params.algo.limits.fr_volume_factor": {"input_type": "double", "label": "Fill Rate Volume Factor", "default": 5.0, "min": 0.0, "max": 100.0, "step": 1.0 },
    "config.params.algo.limits.min_fillrate": {"input_type": "double", "label": "Min Fill Rate", "default": 0.2, "min": 0.0, "max": 1.0, "step": 0.1 },
    "config.params.algo.limits.min_order_distance": {"input_type": "double", "label": "Min Order Distance", "default": 0.001, "min": 0.0, "max": 0.01, "step": 0.001 },
    "config.params.algo.limits.quote_fraction": {"input_type": "double", "label": "Quote Fraction", "default": 0.2, "min": 0.05, "max": 1.0, "step": 0.01 },
}

export const limit_bid_template = {
    "config.params.algo.limits.enable_bid": {
        input_type: "switch",
        label: "Bid Limit Orders",
        color: "primary",
    },
    "config.params.algo.limits.min_bid_utility": {"input_type": "double", "label": "Min Bid Utility", "default": 10.0, "min": 0.0, "max": 100.0, "step": 1.0 },
    "config.params.algo.limits.extra_utility_per_bid": {
        "input_type": "double",
        "label": "Extra Utility per Bid",
        "default": 1.0,
        "min": 0.0,
        "max": 100.0,
        "step": 1.0
    },
    "config.params.algo.limits.min_bid_margin": {"input_type": "double", "label": "Min Bid Margin", "default": -0.0001, "min": -0.001, "max": 0.001, "step": 0.0001 },
    "config.params.algo.limits.bid_pull_threshold": {"input_type": "double", "label": "Bid Pull Threshold", "default": 0.0, "min": -0.002, "max": 0.002, "step": 0.0001 },
    "config.params.algo.limits.bid_stale_threshold": {"input_type": "double", "label": "Bid Stale Threshold", "default": -0.01, "min": -0.1, "max": -0.002, "step": 0.001 },
    "config.params.algo.limits.bid_stale_time_s": {"input_type": "double", "label": "Bid Stale Time (s)", "default": 10.0, "min": 0.0, "max": 30000.0, "step": 1.0 },
    "config.params.algo.limits.max_bid_volatility": {"input_type": "double", "label": "Max Bid Volatility", "default": 1.0, "min": 0.0, "max": 1.0, "step": 0.001 },
}

export const limit_ask_template = {
    "config.params.algo.limits.min_ask_utility": {"input_type": "double", "label": "Min Ask Utility", "default": 10.0, "min": 0.0, "max": 100.0, "step": 1.0 },
    "config.params.algo.limits.ask_pull_threshold": {"input_type": "double", "label": "Ask Pull Threshold", "default": 0.0, "min": -0.002, "max": 0.002, "step": 0.001 },
    "config.params.algo.limits.ask_stale_threshold": {"input_type": "double", "label": "Ask Stale Threshold", "default": 0.01, "min": 0.002, "max": 0.1, "step": 0.001 },
    "config.params.algo.limits.ask_stale_time_s": {"input_type": "double", "label": "Ask Stale Time (s)", "default": 10.0, "min": 0.0, "max": 30000.0, "step": 1.0 },
    "config.params.algo.limits.min_ask_volatility": {"input_type": "double", "label": "Min Ask Volatility", "default": 0.0, "min": 0.0, "max": 1.0, "step": 0.001 },
}

export const hitlift_template = {
    "config.params.algo.hitlifting.enable_bid": {
        input_type: "switch",
        label: "Lift",
        color: "primary",
    },
    "config.params.algo.hitlifting.lift_margin": {
        input_type: "double",
        label: "Lift Margin",
        min: 0.0,
        max: 0.005,
        step: 0.0001,
    },
    "config.params.algo.hitlifting.hit_margin": {
        input_type: "double",
        label: "Hit Margin",
        min: 0.0,
        max: 0.005,
        step: 0.0001,
    },
    "config.params.algo.quant_hitlift.take_profit": {
        input_type: "double",
        label: "Take Profit",
        min: 0.0,
        max: 0.1,
        step: 0.0001,
    },
    "config.params.algo.quant_hitlift.take_loss": {
        input_type: "double",
        label: "Take Loss",
        min: 0.0,
        max: 0.1,
        step: 0.0001,
    },
    /*
    "config.params.algo.hitlifting.hit_low_wait_time_s": {
        input_type: "double",
        label: "Hit Low Wait (s)",
        min: 0.0,
        max: 600.0,
        step: 1.0,
    },
    "config.params.algo.hitlifting.recent_hit_extra_margin": {
        input_type: "double",
        label: "Recent Hit Extra Margin",
        min: 0.0,
        max: 0.01,
        step: 0.0001,
    },
    */
    "config.params.algo.hitlifting.max_position_time": {
        input_type: "double",
        label: "Max Position Time",
        min: 1.0,
        max: 600.0,
        step: 1.0,
    },
    "config.params.algo.hitlifting.max_spread": {
        input_type: "double",
        label: "Max Spread",
        min: 0.0001,
        max: 0.005,
        step: 0.0001,
    },
    "config.params.algo.hitlifting.position_value": {
        input_type: "double",
        label: "Position Value",
        min: 5000.0,
        max: 100000.0,
        step: 1000.0,
    },
    "config.params.algo.hitlifting.resting_time": {
        input_type: "int",
        label: "Resting Time",
        min: 0,
        max: 5000000000,
        step: 1000.0,
    },
}

