const local_dev_config = {
    'demo': true,
    'ALGO_API_URL': 'http://localhost:8000',
    'ORDER_API_URL': 'http://localhost:8000',
    'UPDATE_WS': 'ws://localhost:8000',
    'GOOGLE_CLIENT_ID': '873764445593-t6h2kp21sa2um880562iapstm0dm5biv.apps.googleusercontent.com'
};

const local_tunnel_config = {
    'demo': false,
    'ALGO_API_URL': 'http://localhost:8003',
    'ORDER_API_URL': 'http://localhost:8003',
    'UPDATE_WS': 'ws://localhost:8003',
    'GOOGLE_CLIENT_ID': '873764445593-t6h2kp21sa2um880562iapstm0dm5biv.apps.googleusercontent.com'
};

const production_config = {
    'demo': false,
    'ALGO_API_URL': 'https://chinagirl.orbital.trading:8000',
    'ORDER_API_URL': 'https://chinagirl.orbital.trading:8001',
    'UPDATE_WS': 'wss://chinagirl.orbital.trading:8001',
    'GOOGLE_CLIENT_ID': '873764445593-t6h2kp21sa2um880562iapstm0dm5biv.apps.googleusercontent.com'
};

//export const config = local_dev_config;
//export const config = local_tunnel_config;;
export const config = production_config;
