
export const PnlBpsColumn = (field='pnl_bps', label='PnL BPS') => {
    return {
        field: field,
        headerName: label,
        headerAlign: 'center',
        align: 'right',
        minWidth: 48,
        flex: 2,
        renderCell: (cellValues) => {
            let pnl = Number(cellValues.value);
            let sign=' ';
            let color='text.primary';

            if (pnl > 0) {
                sign='+';
                //color='success.main';
                color='green';
            } else if (pnl < 0) {
                //color = 'error.main';
                color='red';
            }

            const text = `${sign}${pnl.toFixed(2)}`;

            return (
                <div
                  style={{
                      color: color,
                      fontSize: 14,
                      fontWeight: 'bold',
                      width: '100%',
                      textAlign: 'right'
                  }}
                >
                  {text}
                </div>
            );
        }
    }
};
