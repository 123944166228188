import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { send_cancel } from '../lib/actions.js';
import { useGoogleAuth } from '../lib/googleAuth';

interface CancelProps {
    oid: number;
}

export const CancelButton = (props: CancelProps) => {

    const { googleUser } = useGoogleAuth();
    const { oid } = props;

    const [ disabled, set_disabled ] = useState(false);

    const cancel = async () => {
        console.log(`Cancel order ID: ${oid}`);
        set_disabled(true);
        setTimeout(() => set_disabled(false), 3000);
        await send_cancel(googleUser.tokenId, oid);
    }

    return (
        <IconButton 
            aria-label="cancel"
            color = "error"
            disabled={disabled}
            onClick={cancel}
        >
            <HighlightOffIcon/>
        </IconButton>
    );
};
