import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import GridCard from '../components/GridCard.js';

import useIsDesktop from '../lib/isDesktop';
import { show_2dp, show_rmb } from '../lib/number';
import { eastmoney_chart_link } from '../lib/eastmoney';
import { get_stock, get_industry_icon } from '../lib/stocks';
import { get_underlying } from '../lib/underlying';

import {
    select_active_orders,
    select_trades,
    select_position,
    select_all_updates,
} from '../slices/ordersSlice';

import { sx_rows_by_status } from '../styles/order-row.js';

import {
    active_columns,
    position_columns,
    trade_columns,
    stream_columns,
} from '../components/Columns.js';

const Inst = (props) => {
    const params = useParams();
    const isDesktop = useIsDesktop();

    const inst = params.inst;

    const active_orders = useSelector(select_active_orders);
    const trades = useSelector(select_trades);
    const inst_position = useSelector(select_position(inst));
    const all_updates = useSelector(select_all_updates);

    const visible_height = 'calc(100vh - 150px)';
    const half_visible_height = 'calc(50vh - 75px)';

    const is_inst = (x) => {
        return (x.inst == inst);
    }

    const inst_active_orders = active_orders.filter(is_inst);
    const inst_trades = trades.filter(is_inst);
    const inst_all_updates = all_updates.filter(is_inst);

    const stock = get_stock(inst);
    const company_abbrev_cn = stock ? stock["A-share abbreviation"] : "";
    const company_name_cn = stock ? stock["company name"] : "";
    const company_name_en = stock ? stock["English name"] : "";
    const industry_name = stock ? stock["industry"] : "";
    const industry_icon = get_industry_icon(inst);
    const total_capital = stock ? show_rmb(stock["A-share total share capital"]) : "";
    const circulating_capital = stock ? show_rmb(stock["A-share circulating share capital"]) : "";

    const underlying_inst = get_underlying(inst);

    return (
      <Box sx={sx_rows_by_status}>

        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
        }}>
          <h1>
            &nbsp;
            <Link to="/">
              <ArrowBackIcon
                color="primary"
                fontSize="medium"
              />
            </Link>
            &nbsp;
            {inst}
          </h1>
          <Divider
            light={true}
            orientation='vertical'
            sx={{ flex: 0.01 }}
            flexItem
          />
          <p>
            {company_abbrev_cn} ({company_name_cn})<br/>
            {company_name_en}<br/>
          </p>
          <Divider
            light={true}
            orientation='vertical'
            sx={{ flex: 0.01 }}
            flexItem
          />
          <p style={{color: 'grey'}}>
            {industry_icon}<br/>{industry_name}
          </p>
          <Divider
            light={true}
            orientation='vertical'
            sx={{ flex: 0.01 }}
            flexItem
          />
          <p style={{color: 'grey'}}>
            <b>{total_capital}</b> Total<br/>
            <b>{circulating_capital}</b> Cicrulating
          </p>
        </Box>

        { isDesktop &&

        <Box sx={{
            width: '100%',
            height: visible_height,
            display: 'flex',
            flexDirection: 'row'
        }}>

          <div>
            {eastmoney_chart_link(inst)}
            <h3>Underlying {underlying_inst}:</h3>
            {eastmoney_chart_link(underlying_inst)}
          </div>

          <Box sx={{
            width: '50%',
            height: visible_height,
            display: 'flex',
            flexDirection: 'column'
          }}>

            <GridCard
              expanded={true}
              height={300}
              width='100%'
              rows={inst_active_orders}
              columns={active_columns(isDesktop)}
              title="Active Orders"
              noRowsText="No active orders"
            />

            <GridCard
                expanded={true}
                height={half_visible_height}
                width='100%'
                rows={inst_trades}
                //rowHeight={42}
                columns={trade_columns(isDesktop)}
                title="Trades"
                noRowsText="No trades"
            />

          </Box>

          <Box sx={{
            width: '50%',
            height: visible_height,
            display: 'flex',
            flexDirection: 'column'
          }}>

            <GridCard
                expanded={true}
                height={209}
                width='100%'
                rows={[inst_position]}
                rowHeight={42}
                columns={position_columns}
                hideFooterPagination={true}
                title="Position"
                noRowsText="No positions"
            />

            <GridCard
                expanded={true}
                height={half_visible_height}
                width='100%'
                rows={inst_all_updates}
                columns={stream_columns(isDesktop)}
                title="All Updates"
                noRowsText="No updates"
            />

          </Box>

        </Box>
        }

        { !isDesktop &&
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>

        <GridCard
            expanded={false}
            height={209}
            width='100%'
            rows={[inst_position]}
            rowHeight={42}
            columns={position_columns}
            title="Positions"
            noRowsText="No positions"
        />

        <GridCard
            expanded={false}
            height={visible_height}
            width='100%'
            rows={inst_active_orders}
            columns={active_columns(isDesktop)}
            title="Active Orders"
            noRowsText="No active orders"
        />

        <GridCard
            expanded={true}
            height={visible_height}
            width='100%'
            rows={inst_trades}
            //rowHeight={42}
            columns={trade_columns(isDesktop)}
            title="Trades"
            noRowsText="No trades"
        />

        <GridCard
            expanded={true}
            height={visible_height}
            width='100%'
            rows={inst_all_updates}
            columns={stream_columns(isDesktop)}
            title="All Updates"
            noRowsText="No updates"
        />
        </Box>
        }


      </Box>
    );
};

export default React.memo(Inst);
