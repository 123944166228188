import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';

import Typography from '@mui/material/Typography';

import { styled } from "@mui/material/styles";

import { InstDenyButton } from './InstDenyButton';
import InstLabel from './InstLabel';

import { eastmoney_chart_link } from '../lib/eastmoney';
import { show_2dp } from '../lib/number';
import { get_stock } from '../lib/stocks';
import { get_underlying } from '../lib/underlying';

const CardContentNoPadding = styled(CardContent)(`
  padding-top: 0.2;
  padding-bottom: 0;
  padding-left: 0.5;
  padding-right: 0.5;
  &:last-child {
    padding-bottom: 0;
  }
`);

const InstCard = (props) => {
    const init_expanded = props.expanded ? true : false;
    const [expanded, set_expanded] = useState(init_expanded);
    const height = expanded ? props.height : '44px';
    const width = props.width;

    const inst = props.details.inst;
    const vola = props.details.volatility.price;

    const stock = get_stock(inst);
    const company_abbrev_cn = stock ? stock["A-share abbreviation"] : "";
    const company_name_en = stock ? stock["English name"] : "";

    const underlying_inst = get_underlying(inst);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_expanded(event.target.checked);
    }

    const onClick = () => {
        set_expanded(!expanded);
        console.log("CLICKED");
    }

    return (
        <Card sx={{
            height: {height},
            margin: "6px",
            display: 'flex',
            border: '1px solid gray'
        }}>
          <CardContentNoPadding sx={{
              height: '100%',
              width: '100%',
              flex: '1 1 auto'
          }}>

            <Box
                sx={{
                    m: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                }}
                onClick={onClick}
            >

            <Checkbox
                checked={expanded}
                onChange={onChangeHandler}
                icon={<ArrowRight fontSize='medium'/>}
                checkedIcon={<ArrowDropDown fontSize='medium'/>}
                sx={{
                    p: 0
                }}
            />

            <Box sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
            <Typography gutterBottom variant="h6" component="span"><InstLabel inst={inst}/></Typography>

            </Box>
            </Box>

            { expanded &&
                <Box>
                <div>
                    <InstDenyButton props={props.details.denied}/>
                    <Typography variant="subtitle1" component="span">Volatility: <b>{show_2dp(vola)}</b></Typography>
                </div>

                <div>
                    <Typography
                        variant="subtitle2"
                        component="span">{company_name_en}</Typography>
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            marginLeft: '0.5em'
                        }}
                        gutterBottom
                        variant="subtitle1"
                        component="span">{company_abbrev_cn}</Typography>
                </div>


                {eastmoney_chart_link(inst)}
                <Typography variant="subtitle2" component="div">Underlying {underlying_inst}:</Typography>
                {eastmoney_chart_link(underlying_inst)}
                </Box>

            }
          </CardContentNoPadding>
        </Card>
    );
}

export default React.memo(InstCard);
