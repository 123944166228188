import React, {
    createRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';

import Typography from '@mui/material/Typography';

import { styled } from "@mui/material/styles";

import {
    ConfigFromTemplate,
} from '../components/Config';

const CardContentNoPadding = styled(CardContent)(`
  padding-top: 0.2;
  padding-bottom: 0;
  padding-left: 0.5;
  padding-right: 0.5;
  &:last-child {
    padding-bottom: 0;
  }
`);

const ConfigCard = (props) => {
    const refs = useRef({});

    refs.current = Object.fromEntries(Object.entries(props.template).map(([field, x]) => [field, createRef()]));

    const [expanded, set_expanded] = useState(true);

    const refresh = useCallback(async () => {
        for (const field in props.template) {
            refs.current[field].current.doRefresh();
        }
    }, [props.template]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_expanded(event.target.checked);
    }

    const onClick = () => {
        set_expanded(!expanded);
        console.log("CLICKED");
    }

    return (
        <Card sx={{
            height: "90%",
            width: "100%",
            margin: "6px",
            display: 'flex',
            border: '1px solid gray'
          }}
        >
          <CardContentNoPadding sx={{
              height: '90%',
              width: '100%',
              flex: '1 1 auto'
          }}>

            <Box
                sx={{
                    m: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                }}
                onClick={onClick}
            >

            <Checkbox
                checked={expanded}
                onChange={onChangeHandler}
                icon={<ArrowRight fontSize='medium'/>}
                checkedIcon={<ArrowDropDown fontSize='medium'/>}
                sx={{
                    p: 0
                }}
            />

            <Box sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
            <Typography gutterBottom variant="h6" component="span">{props.title}</Typography>

            </Box>
            </Box>
          { expanded &&
          <Box
            sx={{
              maxHeight: '90%',
              overflowY: 'auto'
            }}
          >
          <CardActions>
            <Button
              variant="contained"
              color="warning"
              onClick={refresh}
            >Refresh</Button>
          </CardActions>
          { Object.entries(props.template).map(([field, x]) => {
              return (
                <CardActions key={field}>
                  <ConfigFromTemplate
                    field={field}
                    values={x}
                    ref={refs.current[field]}
                  />
                </CardActions>
              );
            })
          }
          </Box>
          }
          </CardContentNoPadding>
        </Card>
    );
}

export default ConfigCard;
