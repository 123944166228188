import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Divider from '@mui/material/Divider';

import DemoData from './DemoData';
import CurrentTimestamp from './Top/CurrentTimestamp';
import NavigationTabs from './Top/NavigationTabs.js';
import InfoStats from './Top/InfoStats.js';
import MasterControl from './Top/MasterControl';
import PnLStats from './Top/PnLStats';
import UserProfile from './UserProfile';

import { config } from '../lib/config';
import { useIsDesktop, useIsWidescreen } from '../lib/isDesktop';

import { routes } from '../routes';

const TopDesktop = (props) => {
    const isWidescreen = useIsWidescreen();

    return (
        <React.Fragment>
          <AppBar
            position="sticky"
              sx={{
                width: 1
              }}
          >
          <Box sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
          }}>
            <Toolbar
              disableGutters
              sx={{
                width: '100%',
                display: 'inline-flex',
                flexDirection: 'row'
              }}
            >
              <Box sx={{ flex: 2 }}>
                <PnLStats />
              </Box>

              <Box sx={{ flex: 2 }}>
                <CurrentTimestamp />
              </Box>

              <Box sx={{ flex: 0.5 }} />

              <Box sx={{ flex: 6 }}>
                { config.demo && <DemoData /> }
                { !config.demo && <MasterControl /> }
              </Box>

              <Box sx={{ flex: 0.5 }} />

              <Box sx={{ flex: 2 }}>
                <NavigationTabs
                    pathname={props.pathname}
                />
              </Box>

              <Box sx={{ flex: 0.5 }} />

              { isWidescreen &&
                  <PnLStats />
              }

              <Divider
                light={true}
                orientation='vertical'
                flexItem
              />

              <UserProfile />
            </Toolbar>
            <Paper
              elevation={1}
              square={true}
              sx={{
                px: 2,
                width: '100%',
                display: 'inline-flex',
                flexDirection: 'row'
              }}
            >
              <InfoStats/>
            </Paper>
            </Box>
          </AppBar>

          { props.children }
        </React.Fragment>
  );
}

const TopMobile = (props) => {
    const navigate = useNavigate();

    return (
        <Box
        >
          { config.demo && <DemoData /> }
          <AppBar
            position="sticky"
              sx={{
                width: 1
              }}
          >
          <Box sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
          }}>
            <Toolbar
              disableGutters
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <PnLStats />
              <CurrentTimestamp />
              <Box sx={{ flex: 5 }} />
              <Divider
                  light={true}
                  orientation='vertical'
                  flexItem
              />
              <UserProfile />
            </Toolbar>
            <Paper
              elevation={1}
              square={true}
              sx={{
                px: 1,
                width: '100%',
                display: 'inline-flex',
                flexDirection: 'row'
              }}
            >
              <InfoStats/>
            </Paper>
            </Box>
          </AppBar>

          { props.children }

          <BottomNavigation/>

          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

            <BottomNavigation
              position="sticky"
              showLabels
              value={props.pathname}
              onChange={(event, newValue) => {
                navigate(newValue);
              }}
            >
            { routes.map(route =>
                <BottomNavigationAction
                    key={route.path}
                    label={route.label}
                    value={route.path}
                    icon={route.icon}
                    sx={{ my: 3.4 }}
                />)
            }
            </BottomNavigation>
          </Paper>

        </Box>
  );
}

const Top = (props) => {
    const isDesktop = useIsDesktop();
    const location = useLocation();

    if (isDesktop) {
        return (
            <TopDesktop
                pathname={location.pathname}
                {...props}
            />
        );
    } else {
        return (
            <TopMobile
                pathname={location.pathname}
                {... props}
            />
        );
    }
}

export default Top;
